i {
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: left center;
  display: inline-block;
  margin: 0 15px -5px 0;

  // &.globe {
  //   background-image: url('../img/globe-americas-solid.svg');
  // }
}
