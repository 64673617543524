// ==================================================
// Basic Styles
// ==================================================
* {
  box-sizing: border-box;
}

::selection {
  text-shadow: none;
  background: $selection;
  color: $brand-grey;
}

html,
body {
  background-color: $bg-body;
  height: 100%;
  margin: 0;
  min-height: 100%;
  position: relative;
}

body {
  color: $brand;
  font: 16px / 24px $font;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $bg-body;
  height: auto;

  &.scroll {
    overflow: hidden;
  }
}

p:empty {
  display: none;
}
