/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */

.fa-glass:before { content: $fa-var-glass; }
.fa-music:before { content: $fa-var-music; }
.fa-search:before { content: $fa-var-search; }
.fa-envelope-o:before { content: $fa-var-envelope-o; }
.fa-heart:before { content: $fa-var-heart; }
.fa-star:before { content: $fa-var-star; }
.fa-star-o:before { content: $fa-var-star-o; }
.fa-user:before { content: $fa-var-user; }
.fa-film:before { content: $fa-var-film; }
.fa-th-large:before { content: $fa-var-th-large; }
.fa-th:before { content: $fa-var-th; }
.fa-th-list:before { content: $fa-var-th-list; }
.fa-check:before { content: $fa-var-check; }
.fa-remove:before,
.fa-close:before,
.fa-times:before { content: $fa-var-times; }
.fa-search-plus:before { content: $fa-var-search-plus; }
.fa-search-minus:before { content: $fa-var-search-minus; }
.fa-power-off:before { content: $fa-var-power-off; }
.fa-signal:before { content: $fa-var-signal; }
.fa-gear:before,
.fa-cog:before { content: $fa-var-cog; }
.fa-trash-o:before { content: $fa-var-trash-o; }
.fa-home:before { content: $fa-var-home; }
.fa-file-o:before { content: $fa-var-file-o; }
.fa-clock-o:before { content: $fa-var-clock-o; }
.fa-road:before { content: $fa-var-road; }
.fa-download:before { content: $fa-var-download; }
.fa-arrow-circle-o-down:before { content: $fa-var-arrow-circle-o-down; }
.fa-arrow-circle-o-up:before { content: $fa-var-arrow-circle-o-up; }
.fa-inbox:before { content: $fa-var-inbox; }
.fa-play-circle-o:before { content: $fa-var-play-circle-o; }
.fa-rotate-right:before,
.fa-repeat:before { content: $fa-var-repeat; }
.fa-refresh:before { content: $fa-var-refresh; }
.fa-list-alt:before { content: $fa-var-list-alt; }
.fa-lock:before { content: $fa-var-lock; }
.fa-flag:before { content: $fa-var-flag; }
.fa-headphones:before { content: $fa-var-headphones; }
.fa-volume-off:before { content: $fa-var-volume-off; }
.fa-volume-down:before { content: $fa-var-volume-down; }
.fa-volume-up:before { content: $fa-var-volume-up; }
.fa-qrcode:before { content: $fa-var-qrcode; }
.fa-barcode:before { content: $fa-var-barcode; }
.fa-tag:before { content: $fa-var-tag; }
.fa-tags:before { content: $fa-var-tags; }
.fa-book:before { content: $fa-var-book; }
.fa-bookmark:before { content: $fa-var-bookmark; }
.fa-print:before { content: $fa-var-print; }
.fa-camera:before { content: $fa-var-camera; }
.fa-font:before { content: $fa-var-font; }
.fa-bold:before { content: $fa-var-bold; }
.fa-italic:before { content: $fa-var-italic; }
.fa-text-height:before { content: $fa-var-text-height; }
.fa-text-width:before { content: $fa-var-text-width; }
.fa-align-left:before { content: $fa-var-align-left; }
.fa-align-center:before { content: $fa-var-align-center; }
.fa-align-right:before { content: $fa-var-align-right; }
.fa-align-justify:before { content: $fa-var-align-justify; }
.fa-list:before { content: $fa-var-list; }
.fa-dedent:before,
.fa-outdent:before { content: $fa-var-outdent; }
.fa-indent:before { content: $fa-var-indent; }
.fa-video-camera:before { content: $fa-var-video-camera; }
.fa-photo:before,
.fa-image:before,
.fa-picture-o:before { content: $fa-var-picture-o; }
.fa-pencil:before { content: $fa-var-pencil; }
.fa-map-marker:before { content: $fa-var-map-marker; }
.fa-adjust:before { content: $fa-var-adjust; }
.fa-tint:before { content: $fa-var-tint; }
.fa-edit:before,
.fa-pencil-square-o:before { content: $fa-var-pencil-square-o; }
.fa-share-square-o:before { content: $fa-var-share-square-o; }
.fa-check-square-o:before { content: $fa-var-check-square-o; }
.fa-arrows:before { content: $fa-var-arrows; }
.fa-step-backward:before { content: $fa-var-step-backward; }
.fa-fast-backward:before { content: $fa-var-fast-backward; }
.fa-backward:before { content: $fa-var-backward; }
.fa-play:before { content: $fa-var-play; }
.fa-pause:before { content: $fa-var-pause; }
.fa-stop:before { content: $fa-var-stop; }
.fa-forward:before { content: $fa-var-forward; }
.fa-fast-forward:before { content: $fa-var-fast-forward; }
.fa-step-forward:before { content: $fa-var-step-forward; }
.fa-eject:before { content: $fa-var-eject; }
.fa-chevron-left:before { content: $fa-var-chevron-left; }
.fa-chevron-right:before { content: $fa-var-chevron-right; }
.fa-plus-circle:before { content: $fa-var-plus-circle; }
.fa-minus-circle:before { content: $fa-var-minus-circle; }
.fa-times-circle:before { content: $fa-var-times-circle; }
.fa-check-circle:before { content: $fa-var-check-circle; }
.fa-question-circle:before { content: $fa-var-question-circle; }
.fa-info-circle:before { content: $fa-var-info-circle; }
.fa-crosshairs:before { content: $fa-var-crosshairs; }
.fa-times-circle-o:before { content: $fa-var-times-circle-o; }
.fa-check-circle-o:before { content: $fa-var-check-circle-o; }
.fa-ban:before { content: $fa-var-ban; }
.fa-arrow-left:before { content: $fa-var-arrow-left; }
.fa-arrow-right:before { content: $fa-var-arrow-right; }
.fa-arrow-up:before { content: $fa-var-arrow-up; }
.fa-arrow-down:before { content: $fa-var-arrow-down; }
.fa-mail-forward:before,
.fa-share:before { content: $fa-var-share; }
.fa-expand:before { content: $fa-var-expand; }
.fa-compress:before { content: $fa-var-compress; }
.fa-plus:before { content: $fa-var-plus; }
.fa-minus:before { content: $fa-var-minus; }
.fa-asterisk:before { content: $fa-var-asterisk; }
.fa-exclamation-circle:before { content: $fa-var-exclamation-circle; }
.fa-gift:before { content: $fa-var-gift; }
.fa-leaf:before { content: $fa-var-leaf; }
.fa-fire:before { content: $fa-var-fire; }
.fa-eye:before { content: $fa-var-eye; }
.fa-eye-slash:before { content: $fa-var-eye-slash; }
.fa-warning:before,
.fa-exclamation-triangle:before { content: $fa-var-exclamation-triangle; }
.fa-plane:before { content: $fa-var-plane; }
.fa-calendar:before { content: $fa-var-calendar; }
.fa-random:before { content: $fa-var-random; }
.fa-comment:before { content: $fa-var-comment; }
.fa-magnet:before { content: $fa-var-magnet; }
.fa-chevron-up:before { content: $fa-var-chevron-up; }
.fa-chevron-down:before { content: $fa-var-chevron-down; }
.fa-retweet:before { content: $fa-var-retweet; }
.fa-shopping-cart:before { content: $fa-var-shopping-cart; }
.fa-folder:before { content: $fa-var-folder; }
.fa-folder-open:before { content: $fa-var-folder-open; }
.fa-arrows-v:before { content: $fa-var-arrows-v; }
.fa-arrows-h:before { content: $fa-var-arrows-h; }
.fa-bar-chart-o:before,
.fa-bar-chart:before { content: $fa-var-bar-chart; }
.fa-twitter-square:before { content: $fa-var-twitter-square; }
.fa-facebook-square:before { content: $fa-var-facebook-square; }
.fa-camera-retro:before { content: $fa-var-camera-retro; }
.fa-key:before { content: $fa-var-key; }
.fa-gears:before,
.fa-cogs:before { content: $fa-var-cogs; }
.fa-comments:before { content: $fa-var-comments; }
.fa-thumbs-o-up:before { content: $fa-var-thumbs-o-up; }
.fa-thumbs-o-down:before { content: $fa-var-thumbs-o-down; }
.fa-star-half:before { content: $fa-var-star-half; }
.fa-heart-o:before { content: $fa-var-heart-o; }
.fa-sign-out:before { content: $fa-var-sign-out; }
.fa-linkedin-square:before { content: $fa-var-linkedin-square; }
.fa-thumb-tack:before { content: $fa-var-thumb-tack; }
.fa-external-link:before { content: $fa-var-external-link; }
.fa-sign-in:before { content: $fa-var-sign-in; }
.fa-trophy:before { content: $fa-var-trophy; }
.fa-github-square:before { content: $fa-var-github-square; }
.fa-upload:before { content: $fa-var-upload; }
.fa-lemon-o:before { content: $fa-var-lemon-o; }
.fa-phone:before { content: $fa-var-phone; }
.fa-square-o:before { content: $fa-var-square-o; }
.fa-bookmark-o:before { content: $fa-var-bookmark-o; }
.fa-phone-square:before { content: $fa-var-phone-square; }
.fa-twitter:before { content: $fa-var-twitter; }
.fa-facebook-f:before,
.fa-facebook:before { content: $fa-var-facebook; }
.fa-github:before { content: $fa-var-github; }
.fa-unlock:before { content: $fa-var-unlock; }
.fa-credit-card:before { content: $fa-var-credit-card; }
.fa-feed:before,
.fa-rss:before { content: $fa-var-rss; }
.fa-hdd-o:before { content: $fa-var-hdd-o; }
.fa-bullhorn:before { content: $fa-var-bullhorn; }
.fa-bell:before { content: $fa-var-bell; }
.fa-certificate:before { content: $fa-var-certificate; }
.fa-hand-o-right:before { content: $fa-var-hand-o-right; }
.fa-hand-o-left:before { content: $fa-var-hand-o-left; }
.fa-hand-o-up:before { content: $fa-var-hand-o-up; }
.fa-hand-o-down:before { content: $fa-var-hand-o-down; }
.fa-arrow-circle-left:before { content: $fa-var-arrow-circle-left; }
.fa-arrow-circle-right:before { content: $fa-var-arrow-circle-right; }
.fa-arrow-circle-up:before { content: $fa-var-arrow-circle-up; }
.fa-arrow-circle-down:before { content: $fa-var-arrow-circle-down; }
.fa-globe:before { content: $fa-var-globe; }
.fa-wrench:before { content: $fa-var-wrench; }
.fa-tasks:before { content: $fa-var-tasks; }
.fa-filter:before { content: $fa-var-filter; }
.fa-briefcase:before { content: $fa-var-briefcase; }
.fa-arrows-alt:before { content: $fa-var-arrows-alt; }
.fa-group:before,
.fa-users:before { content: $fa-var-users; }
.fa-chain:before,
.fa-link:before { content: $fa-var-link; }
.fa-cloud:before { content: $fa-var-cloud; }
.fa-flask:before { content: $fa-var-flask; }
.fa-cut:before,
.fa-scissors:before { content: $fa-var-scissors; }
.fa-copy:before,
.fa-files-o:before { content: $fa-var-files-o; }
.fa-paperclip:before { content: $fa-var-paperclip; }
.fa-save:before,
.fa-floppy-o:before { content: $fa-var-floppy-o; }
.fa-square:before { content: $fa-var-square; }
.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before { content: $fa-var-bars; }
.fa-list-ul:before { content: $fa-var-list-ul; }
.fa-list-ol:before { content: $fa-var-list-ol; }
.fa-strikethrough:before { content: $fa-var-strikethrough; }
.fa-underline:before { content: $fa-var-underline; }
.fa-table:before { content: $fa-var-table; }
.fa-magic:before { content: $fa-var-magic; }
.fa-truck:before { content: $fa-var-truck; }
.fa-pinterest:before { content: $fa-var-pinterest; }
.fa-pinterest-square:before { content: $fa-var-pinterest-square; }
.fa-google-plus-square:before { content: $fa-var-google-plus-square; }
.fa-google-plus:before { content: $fa-var-google-plus; }
.fa-money:before { content: $fa-var-money; }
.fa-caret-down:before { content: $fa-var-caret-down; }
.fa-caret-up:before { content: $fa-var-caret-up; }
.fa-caret-left:before { content: $fa-var-caret-left; }
.fa-caret-right:before { content: $fa-var-caret-right; }
.fa-columns:before { content: $fa-var-columns; }
.fa-unsorted:before,
.fa-sort:before { content: $fa-var-sort; }
.fa-sort-down:before,
.fa-sort-desc:before { content: $fa-var-sort-desc; }
.fa-sort-up:before,
.fa-sort-asc:before { content: $fa-var-sort-asc; }
.fa-envelope:before { content: $fa-var-envelope; }
.fa-linkedin:before { content: $fa-var-linkedin; }
.fa-rotate-left:before,
.fa-undo:before { content: $fa-var-undo; }
.fa-legal:before,
.fa-gavel:before { content: $fa-var-gavel; }
.fa-dashboard:before,
.fa-tachometer:before { content: $fa-var-tachometer; }
.fa-comment-o:before { content: $fa-var-comment-o; }
.fa-comments-o:before { content: $fa-var-comments-o; }
.fa-flash:before,
.fa-bolt:before { content: $fa-var-bolt; }
.fa-sitemap:before { content: $fa-var-sitemap; }
.fa-umbrella:before { content: $fa-var-umbrella; }
.fa-paste:before,
.fa-clipboard:before { content: $fa-var-clipboard; }
.fa-lightbulb-o:before { content: $fa-var-lightbulb-o; }
.fa-exchange:before { content: $fa-var-exchange; }
.fa-cloud-download:before { content: $fa-var-cloud-download; }
.fa-cloud-upload:before { content: $fa-var-cloud-upload; }
.fa-user-md:before { content: $fa-var-user-md; }
.fa-stethoscope:before { content: $fa-var-stethoscope; }
.fa-suitcase:before { content: $fa-var-suitcase; }
.fa-bell-o:before { content: $fa-var-bell-o; }
.fa-coffee:before { content: $fa-var-coffee; }
.fa-cutlery:before { content: $fa-var-cutlery; }
.fa-file-text-o:before { content: $fa-var-file-text-o; }
.fa-building-o:before { content: $fa-var-building-o; }
.fa-hospital-o:before { content: $fa-var-hospital-o; }
.fa-ambulance:before { content: $fa-var-ambulance; }
.fa-medkit:before { content: $fa-var-medkit; }
.fa-fighter-jet:before { content: $fa-var-fighter-jet; }
.fa-beer:before { content: $fa-var-beer; }
.fa-h-square:before { content: $fa-var-h-square; }
.fa-plus-square:before { content: $fa-var-plus-square; }
.fa-angle-double-left:before { content: $fa-var-angle-double-left; }
.fa-angle-double-right:before { content: $fa-var-angle-double-right; }
.fa-angle-double-up:before { content: $fa-var-angle-double-up; }
.fa-angle-double-down:before { content: $fa-var-angle-double-down; }
.fa-angle-left:before { content: $fa-var-angle-left; }
.fa-angle-right:before { content: $fa-var-angle-right; }
.fa-angle-up:before { content: $fa-var-angle-up; }
.fa-angle-down:before { content: $fa-var-angle-down; }
.fa-desktop:before { content: $fa-var-desktop; }
.fa-laptop:before { content: $fa-var-laptop; }
.fa-tablet:before { content: $fa-var-tablet; }
.fa-mobile-phone:before,
.fa-mobile:before { content: $fa-var-mobile; }
.fa-circle-o:before { content: $fa-var-circle-o; }
.fa-quote-left:before { content: $fa-var-quote-left; }
.fa-quote-right:before { content: $fa-var-quote-right; }
.fa-spinner:before { content: $fa-var-spinner; }
.fa-circle:before { content: $fa-var-circle; }
.fa-mail-reply:before,
.fa-reply:before { content: $fa-var-reply; }
.fa-github-alt:before { content: $fa-var-github-alt; }
.fa-folder-o:before { content: $fa-var-folder-o; }
.fa-folder-open-o:before { content: $fa-var-folder-open-o; }
.fa-smile-o:before { content: $fa-var-smile-o; }
.fa-frown-o:before { content: $fa-var-frown-o; }
.fa-meh-o:before { content: $fa-var-meh-o; }
.fa-gamepad:before { content: $fa-var-gamepad; }
.fa-keyboard-o:before { content: $fa-var-keyboard-o; }
.fa-flag-o:before { content: $fa-var-flag-o; }
.fa-flag-checkered:before { content: $fa-var-flag-checkered; }
.fa-terminal:before { content: $fa-var-terminal; }
.fa-code:before { content: $fa-var-code; }
.fa-mail-reply-all:before,
.fa-reply-all:before { content: $fa-var-reply-all; }
.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before { content: $fa-var-star-half-o; }
.fa-location-arrow:before { content: $fa-var-location-arrow; }
.fa-crop:before { content: $fa-var-crop; }
.fa-code-fork:before { content: $fa-var-code-fork; }
.fa-unlink:before,
.fa-chain-broken:before { content: $fa-var-chain-broken; }
.fa-question:before { content: $fa-var-question; }
.fa-info:before { content: $fa-var-info; }
.fa-exclamation:before { content: $fa-var-exclamation; }
.fa-superscript:before { content: $fa-var-superscript; }
.fa-subscript:before { content: $fa-var-subscript; }
.fa-eraser:before { content: $fa-var-eraser; }
.fa-puzzle-piece:before { content: $fa-var-puzzle-piece; }
.fa-microphone:before { content: $fa-var-microphone; }
.fa-microphone-slash:before { content: $fa-var-microphone-slash; }
.fa-shield:before { content: $fa-var-shield; }
.fa-calendar-o:before { content: $fa-var-calendar-o; }
.fa-fire-extinguisher:before { content: $fa-var-fire-extinguisher; }
.fa-rocket:before { content: $fa-var-rocket; }
.fa-maxcdn:before { content: $fa-var-maxcdn; }
.fa-chevron-circle-left:before { content: $fa-var-chevron-circle-left; }
.fa-chevron-circle-right:before { content: $fa-var-chevron-circle-right; }
.fa-chevron-circle-up:before { content: $fa-var-chevron-circle-up; }
.fa-chevron-circle-down:before { content: $fa-var-chevron-circle-down; }
.fa-html5:before { content: $fa-var-html5; }
.fa-css3:before { content: $fa-var-css3; }
.fa-anchor:before { content: $fa-var-anchor; }
.fa-unlock-alt:before { content: $fa-var-unlock-alt; }
.fa-bullseye:before { content: $fa-var-bullseye; }
.fa-ellipsis-h:before { content: $fa-var-ellipsis-h; }
.fa-ellipsis-v:before { content: $fa-var-ellipsis-v; }
.fa-rss-square:before { content: $fa-var-rss-square; }
.fa-play-circle:before { content: $fa-var-play-circle; }
.fa-ticket:before { content: $fa-var-ticket; }
.fa-minus-square:before { content: $fa-var-minus-square; }
.fa-minus-square-o:before { content: $fa-var-minus-square-o; }
.fa-level-up:before { content: $fa-var-level-up; }
.fa-level-down:before { content: $fa-var-level-down; }
.fa-check-square:before { content: $fa-var-check-square; }
.fa-pencil-square:before { content: $fa-var-pencil-square; }
.fa-external-link-square:before { content: $fa-var-external-link-square; }
.fa-share-square:before { content: $fa-var-share-square; }
.fa-compass:before { content: $fa-var-compass; }
.fa-toggle-down:before,
.fa-caret-square-o-down:before { content: $fa-var-caret-square-o-down; }
.fa-toggle-up:before,
.fa-caret-square-o-up:before { content: $fa-var-caret-square-o-up; }
.fa-toggle-right:before,
.fa-caret-square-o-right:before { content: $fa-var-caret-square-o-right; }
.fa-euro:before,
.fa-eur:before { content: $fa-var-eur; }
.fa-gbp:before { content: $fa-var-gbp; }
.fa-dollar:before,
.fa-usd:before { content: $fa-var-usd; }
.fa-rupee:before,
.fa-inr:before { content: $fa-var-inr; }
.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before { content: $fa-var-jpy; }
.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before { content: $fa-var-rub; }
.fa-won:before,
.fa-krw:before { content: $fa-var-krw; }
.fa-bitcoin:before,
.fa-btc:before { content: $fa-var-btc; }
.fa-file:before { content: $fa-var-file; }
.fa-file-text:before { content: $fa-var-file-text; }
.fa-sort-alpha-asc:before { content: $fa-var-sort-alpha-asc; }
.fa-sort-alpha-desc:before { content: $fa-var-sort-alpha-desc; }
.fa-sort-amount-asc:before { content: $fa-var-sort-amount-asc; }
.fa-sort-amount-desc:before { content: $fa-var-sort-amount-desc; }
.fa-sort-numeric-asc:before { content: $fa-var-sort-numeric-asc; }
.fa-sort-numeric-desc:before { content: $fa-var-sort-numeric-desc; }
.fa-thumbs-up:before { content: $fa-var-thumbs-up; }
.fa-thumbs-down:before { content: $fa-var-thumbs-down; }
.fa-youtube-square:before { content: $fa-var-youtube-square; }
.fa-youtube:before { content: $fa-var-youtube; }
.fa-xing:before { content: $fa-var-xing; }
.fa-xing-square:before { content: $fa-var-xing-square; }
.fa-youtube-play:before { content: $fa-var-youtube-play; }
.fa-dropbox:before { content: $fa-var-dropbox; }
.fa-stack-overflow:before { content: $fa-var-stack-overflow; }
.fa-instagram:before { content: $fa-var-instagram; }
.fa-flickr:before { content: $fa-var-flickr; }
.fa-adn:before { content: $fa-var-adn; }
.fa-bitbucket:before { content: $fa-var-bitbucket; }
.fa-bitbucket-square:before { content: $fa-var-bitbucket-square; }
.fa-tumblr:before { content: $fa-var-tumblr; }
.fa-tumblr-square:before { content: $fa-var-tumblr-square; }
.fa-long-arrow-down:before { content: $fa-var-long-arrow-down; }
.fa-long-arrow-up:before { content: $fa-var-long-arrow-up; }
.fa-long-arrow-left:before { content: $fa-var-long-arrow-left; }
.fa-long-arrow-right:before { content: $fa-var-long-arrow-right; }
.fa-apple:before { content: $fa-var-apple; }
.fa-windows:before { content: $fa-var-windows; }
.fa-android:before { content: $fa-var-android; }
.fa-linux:before { content: $fa-var-linux; }
.fa-dribbble:before { content: $fa-var-dribbble; }
.fa-skype:before { content: $fa-var-skype; }
.fa-foursquare:before { content: $fa-var-foursquare; }
.fa-trello:before { content: $fa-var-trello; }
.fa-female:before { content: $fa-var-female; }
.fa-male:before { content: $fa-var-male; }
.fa-gittip:before,
.fa-gratipay:before { content: $fa-var-gratipay; }
.fa-sun-o:before { content: $fa-var-sun-o; }
.fa-moon-o:before { content: $fa-var-moon-o; }
.fa-archive:before { content: $fa-var-archive; }
.fa-bug:before { content: $fa-var-bug; }
.fa-vk:before { content: $fa-var-vk; }
.fa-weibo:before { content: $fa-var-weibo; }
.fa-renren:before { content: $fa-var-renren; }
.fa-pagelines:before { content: $fa-var-pagelines; }
.fa-stack-exchange:before { content: $fa-var-stack-exchange; }
.fa-arrow-circle-o-right:before { content: $fa-var-arrow-circle-o-right; }
.fa-arrow-circle-o-left:before { content: $fa-var-arrow-circle-o-left; }
.fa-toggle-left:before,
.fa-caret-square-o-left:before { content: $fa-var-caret-square-o-left; }
.fa-dot-circle-o:before { content: $fa-var-dot-circle-o; }
.fa-wheelchair:before { content: $fa-var-wheelchair; }
.fa-vimeo-square:before { content: $fa-var-vimeo-square; }
.fa-turkish-lira:before,
.fa-try:before { content: $fa-var-try; }
.fa-plus-square-o:before { content: $fa-var-plus-square-o; }
.fa-space-shuttle:before { content: $fa-var-space-shuttle; }
.fa-slack:before { content: $fa-var-slack; }
.fa-envelope-square:before { content: $fa-var-envelope-square; }
.fa-wordpress:before { content: $fa-var-wordpress; }
.fa-openid:before { content: $fa-var-openid; }
.fa-institution:before,
.fa-bank:before,
.fa-university:before { content: $fa-var-university; }
.fa-mortar-board:before,
.fa-graduation-cap:before { content: $fa-var-graduation-cap; }
.fa-yahoo:before { content: $fa-var-yahoo; }
.fa-google:before { content: $fa-var-google; }
.fa-reddit:before { content: $fa-var-reddit; }
.fa-reddit-square:before { content: $fa-var-reddit-square; }
.fa-stumbleupon-circle:before { content: $fa-var-stumbleupon-circle; }
.fa-stumbleupon:before { content: $fa-var-stumbleupon; }
.fa-delicious:before { content: $fa-var-delicious; }
.fa-digg:before { content: $fa-var-digg; }
.fa-pied-piper-pp:before { content: $fa-var-pied-piper-pp; }
.fa-pied-piper-alt:before { content: $fa-var-pied-piper-alt; }
.fa-drupal:before { content: $fa-var-drupal; }
.fa-joomla:before { content: $fa-var-joomla; }
.fa-language:before { content: $fa-var-language; }
.fa-fax:before { content: $fa-var-fax; }
.fa-building:before { content: $fa-var-building; }
.fa-child:before { content: $fa-var-child; }
.fa-paw:before { content: $fa-var-paw; }
.fa-spoon:before { content: $fa-var-spoon; }
.fa-cube:before { content: $fa-var-cube; }
.fa-cubes:before { content: $fa-var-cubes; }
.fa-behance:before { content: $fa-var-behance; }
.fa-behance-square:before { content: $fa-var-behance-square; }
.fa-steam:before { content: $fa-var-steam; }
.fa-steam-square:before { content: $fa-var-steam-square; }
.fa-recycle:before { content: $fa-var-recycle; }
.fa-automobile:before,
.fa-car:before { content: $fa-var-car; }
.fa-cab:before,
.fa-taxi:before { content: $fa-var-taxi; }
.fa-tree:before { content: $fa-var-tree; }
.fa-spotify:before { content: $fa-var-spotify; }
.fa-deviantart:before { content: $fa-var-deviantart; }
.fa-soundcloud:before { content: $fa-var-soundcloud; }
.fa-database:before { content: $fa-var-database; }
.fa-file-pdf-o:before { content: $fa-var-file-pdf-o; }
.fa-file-word-o:before { content: $fa-var-file-word-o; }
.fa-file-excel-o:before { content: $fa-var-file-excel-o; }
.fa-file-powerpoint-o:before { content: $fa-var-file-powerpoint-o; }
.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before { content: $fa-var-file-image-o; }
.fa-file-zip-o:before,
.fa-file-archive-o:before { content: $fa-var-file-archive-o; }
.fa-file-sound-o:before,
.fa-file-audio-o:before { content: $fa-var-file-audio-o; }
.fa-file-movie-o:before,
.fa-file-video-o:before { content: $fa-var-file-video-o; }
.fa-file-code-o:before { content: $fa-var-file-code-o; }
.fa-vine:before { content: $fa-var-vine; }
.fa-codepen:before { content: $fa-var-codepen; }
.fa-jsfiddle:before { content: $fa-var-jsfiddle; }
.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before { content: $fa-var-life-ring; }
.fa-circle-o-notch:before { content: $fa-var-circle-o-notch; }
.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before { content: $fa-var-rebel; }
.fa-ge:before,
.fa-empire:before { content: $fa-var-empire; }
.fa-git-square:before { content: $fa-var-git-square; }
.fa-git:before { content: $fa-var-git; }
.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before { content: $fa-var-hacker-news; }
.fa-tencent-weibo:before { content: $fa-var-tencent-weibo; }
.fa-qq:before { content: $fa-var-qq; }
.fa-wechat:before,
.fa-weixin:before { content: $fa-var-weixin; }
.fa-send:before,
.fa-paper-plane:before { content: $fa-var-paper-plane; }
.fa-send-o:before,
.fa-paper-plane-o:before { content: $fa-var-paper-plane-o; }
.fa-history:before { content: $fa-var-history; }
.fa-circle-thin:before { content: $fa-var-circle-thin; }
.fa-header:before { content: $fa-var-header; }
.fa-paragraph:before { content: $fa-var-paragraph; }
.fa-sliders:before { content: $fa-var-sliders; }
.fa-share-alt:before { content: $fa-var-share-alt; }
.fa-share-alt-square:before { content: $fa-var-share-alt-square; }
.fa-bomb:before { content: $fa-var-bomb; }
.fa-soccer-ball-o:before,
.fa-futbol-o:before { content: $fa-var-futbol-o; }
.fa-tty:before { content: $fa-var-tty; }
.fa-binoculars:before { content: $fa-var-binoculars; }
.fa-plug:before { content: $fa-var-plug; }
.fa-slideshare:before { content: $fa-var-slideshare; }
.fa-twitch:before { content: $fa-var-twitch; }
.fa-yelp:before { content: $fa-var-yelp; }
.fa-newspaper-o:before { content: $fa-var-newspaper-o; }
.fa-wifi:before { content: $fa-var-wifi; }
.fa-calculator:before { content: $fa-var-calculator; }
.fa-paypal:before { content: $fa-var-paypal; }
.fa-google-wallet:before { content: $fa-var-google-wallet; }
.fa-cc-visa:before { content: $fa-var-cc-visa; }
.fa-cc-mastercard:before { content: $fa-var-cc-mastercard; }
.fa-cc-discover:before { content: $fa-var-cc-discover; }
.fa-cc-amex:before { content: $fa-var-cc-amex; }
.fa-cc-paypal:before { content: $fa-var-cc-paypal; }
.fa-cc-stripe:before { content: $fa-var-cc-stripe; }
.fa-bell-slash:before { content: $fa-var-bell-slash; }
.fa-bell-slash-o:before { content: $fa-var-bell-slash-o; }
.fa-trash:before { content: $fa-var-trash; }
.fa-copyright:before { content: $fa-var-copyright; }
.fa-at:before { content: $fa-var-at; }
.fa-eyedropper:before { content: $fa-var-eyedropper; }
.fa-paint-brush:before { content: $fa-var-paint-brush; }
.fa-birthday-cake:before { content: $fa-var-birthday-cake; }
.fa-area-chart:before { content: $fa-var-area-chart; }
.fa-pie-chart:before { content: $fa-var-pie-chart; }
.fa-line-chart:before { content: $fa-var-line-chart; }
.fa-lastfm:before { content: $fa-var-lastfm; }
.fa-lastfm-square:before { content: $fa-var-lastfm-square; }
.fa-toggle-off:before { content: $fa-var-toggle-off; }
.fa-toggle-on:before { content: $fa-var-toggle-on; }
.fa-bicycle:before { content: $fa-var-bicycle; }
.fa-bus:before { content: $fa-var-bus; }
.fa-ioxhost:before { content: $fa-var-ioxhost; }
.fa-angellist:before { content: $fa-var-angellist; }
.fa-cc:before { content: $fa-var-cc; }
.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before { content: $fa-var-ils; }
.fa-meanpath:before { content: $fa-var-meanpath; }
.fa-buysellads:before { content: $fa-var-buysellads; }
.fa-connectdevelop:before { content: $fa-var-connectdevelop; }
.fa-dashcube:before { content: $fa-var-dashcube; }
.fa-forumbee:before { content: $fa-var-forumbee; }
.fa-leanpub:before { content: $fa-var-leanpub; }
.fa-sellsy:before { content: $fa-var-sellsy; }
.fa-shirtsinbulk:before { content: $fa-var-shirtsinbulk; }
.fa-simplybuilt:before { content: $fa-var-simplybuilt; }
.fa-skyatlas:before { content: $fa-var-skyatlas; }
.fa-cart-plus:before { content: $fa-var-cart-plus; }
.fa-cart-arrow-down:before { content: $fa-var-cart-arrow-down; }
.fa-diamond:before { content: $fa-var-diamond; }
.fa-ship:before { content: $fa-var-ship; }
.fa-user-secret:before { content: $fa-var-user-secret; }
.fa-motorcycle:before { content: $fa-var-motorcycle; }
.fa-street-view:before { content: $fa-var-street-view; }
.fa-heartbeat:before { content: $fa-var-heartbeat; }
.fa-venus:before { content: $fa-var-venus; }
.fa-mars:before { content: $fa-var-mars; }
.fa-mercury:before { content: $fa-var-mercury; }
.fa-intersex:before,
.fa-transgender:before { content: $fa-var-transgender; }
.fa-transgender-alt:before { content: $fa-var-transgender-alt; }
.fa-venus-double:before { content: $fa-var-venus-double; }
.fa-mars-double:before { content: $fa-var-mars-double; }
.fa-venus-mars:before { content: $fa-var-venus-mars; }
.fa-mars-stroke:before { content: $fa-var-mars-stroke; }
.fa-mars-stroke-v:before { content: $fa-var-mars-stroke-v; }
.fa-mars-stroke-h:before { content: $fa-var-mars-stroke-h; }
.fa-neuter:before { content: $fa-var-neuter; }
.fa-genderless:before { content: $fa-var-genderless; }
.fa-facebook-official:before { content: $fa-var-facebook-official; }
.fa-pinterest-p:before { content: $fa-var-pinterest-p; }
.fa-whatsapp:before { content: $fa-var-whatsapp; }
.fa-server:before { content: $fa-var-server; }
.fa-user-plus:before { content: $fa-var-user-plus; }
.fa-user-times:before { content: $fa-var-user-times; }
.fa-hotel:before,
.fa-bed:before { content: $fa-var-bed; }
.fa-viacoin:before { content: $fa-var-viacoin; }
.fa-train:before { content: $fa-var-train; }
.fa-subway:before { content: $fa-var-subway; }
.fa-medium:before { content: $fa-var-medium; }
.fa-yc:before,
.fa-y-combinator:before { content: $fa-var-y-combinator; }
.fa-optin-monster:before { content: $fa-var-optin-monster; }
.fa-opencart:before { content: $fa-var-opencart; }
.fa-expeditedssl:before { content: $fa-var-expeditedssl; }
.fa-battery-4:before,
.fa-battery-full:before { content: $fa-var-battery-full; }
.fa-battery-3:before,
.fa-battery-three-quarters:before { content: $fa-var-battery-three-quarters; }
.fa-battery-2:before,
.fa-battery-half:before { content: $fa-var-battery-half; }
.fa-battery-1:before,
.fa-battery-quarter:before { content: $fa-var-battery-quarter; }
.fa-battery-0:before,
.fa-battery-empty:before { content: $fa-var-battery-empty; }
.fa-mouse-pointer:before { content: $fa-var-mouse-pointer; }
.fa-i-cursor:before { content: $fa-var-i-cursor; }
.fa-object-group:before { content: $fa-var-object-group; }
.fa-object-ungroup:before { content: $fa-var-object-ungroup; }
.fa-sticky-note:before { content: $fa-var-sticky-note; }
.fa-sticky-note-o:before { content: $fa-var-sticky-note-o; }
.fa-cc-jcb:before { content: $fa-var-cc-jcb; }
.fa-cc-diners-club:before { content: $fa-var-cc-diners-club; }
.fa-clone:before { content: $fa-var-clone; }
.fa-balance-scale:before { content: $fa-var-balance-scale; }
.fa-hourglass-o:before { content: $fa-var-hourglass-o; }
.fa-hourglass-1:before,
.fa-hourglass-start:before { content: $fa-var-hourglass-start; }
.fa-hourglass-2:before,
.fa-hourglass-half:before { content: $fa-var-hourglass-half; }
.fa-hourglass-3:before,
.fa-hourglass-end:before { content: $fa-var-hourglass-end; }
.fa-hourglass:before { content: $fa-var-hourglass; }
.fa-hand-grab-o:before,
.fa-hand-rock-o:before { content: $fa-var-hand-rock-o; }
.fa-hand-stop-o:before,
.fa-hand-paper-o:before { content: $fa-var-hand-paper-o; }
.fa-hand-scissors-o:before { content: $fa-var-hand-scissors-o; }
.fa-hand-lizard-o:before { content: $fa-var-hand-lizard-o; }
.fa-hand-spock-o:before { content: $fa-var-hand-spock-o; }
.fa-hand-pointer-o:before { content: $fa-var-hand-pointer-o; }
.fa-hand-peace-o:before { content: $fa-var-hand-peace-o; }
.fa-trademark:before { content: $fa-var-trademark; }
.fa-registered:before { content: $fa-var-registered; }
.fa-creative-commons:before { content: $fa-var-creative-commons; }
.fa-gg:before { content: $fa-var-gg; }
.fa-gg-circle:before { content: $fa-var-gg-circle; }
.fa-tripadvisor:before { content: $fa-var-tripadvisor; }
.fa-odnoklassniki:before { content: $fa-var-odnoklassniki; }
.fa-odnoklassniki-square:before { content: $fa-var-odnoklassniki-square; }
.fa-get-pocket:before { content: $fa-var-get-pocket; }
.fa-wikipedia-w:before { content: $fa-var-wikipedia-w; }
.fa-safari:before { content: $fa-var-safari; }
.fa-chrome:before { content: $fa-var-chrome; }
.fa-firefox:before { content: $fa-var-firefox; }
.fa-opera:before { content: $fa-var-opera; }
.fa-internet-explorer:before { content: $fa-var-internet-explorer; }
.fa-tv:before,
.fa-television:before { content: $fa-var-television; }
.fa-contao:before { content: $fa-var-contao; }
.fa-500px:before { content: $fa-var-500px; }
.fa-amazon:before { content: $fa-var-amazon; }
.fa-calendar-plus-o:before { content: $fa-var-calendar-plus-o; }
.fa-calendar-minus-o:before { content: $fa-var-calendar-minus-o; }
.fa-calendar-times-o:before { content: $fa-var-calendar-times-o; }
.fa-calendar-check-o:before { content: $fa-var-calendar-check-o; }
.fa-industry:before { content: $fa-var-industry; }
.fa-map-pin:before { content: $fa-var-map-pin; }
.fa-map-signs:before { content: $fa-var-map-signs; }
.fa-map-o:before { content: $fa-var-map-o; }
.fa-map:before { content: $fa-var-map; }
.fa-commenting:before { content: $fa-var-commenting; }
.fa-commenting-o:before { content: $fa-var-commenting-o; }
.fa-houzz:before { content: $fa-var-houzz; }
.fa-vimeo:before { content: $fa-var-vimeo; }
.fa-black-tie:before { content: $fa-var-black-tie; }
.fa-fonticons:before { content: $fa-var-fonticons; }
.fa-reddit-alien:before { content: $fa-var-reddit-alien; }
.fa-edge:before { content: $fa-var-edge; }
.fa-credit-card-alt:before { content: $fa-var-credit-card-alt; }
.fa-codiepie:before { content: $fa-var-codiepie; }
.fa-modx:before { content: $fa-var-modx; }
.fa-fort-awesome:before { content: $fa-var-fort-awesome; }
.fa-usb:before { content: $fa-var-usb; }
.fa-product-hunt:before { content: $fa-var-product-hunt; }
.fa-mixcloud:before { content: $fa-var-mixcloud; }
.fa-scribd:before { content: $fa-var-scribd; }
.fa-pause-circle:before { content: $fa-var-pause-circle; }
.fa-pause-circle-o:before { content: $fa-var-pause-circle-o; }
.fa-stop-circle:before { content: $fa-var-stop-circle; }
.fa-stop-circle-o:before { content: $fa-var-stop-circle-o; }
.fa-shopping-bag:before { content: $fa-var-shopping-bag; }
.fa-shopping-basket:before { content: $fa-var-shopping-basket; }
.fa-hashtag:before { content: $fa-var-hashtag; }
.fa-bluetooth:before { content: $fa-var-bluetooth; }
.fa-bluetooth-b:before { content: $fa-var-bluetooth-b; }
.fa-percent:before { content: $fa-var-percent; }
.fa-gitlab:before { content: $fa-var-gitlab; }
.fa-wpbeginner:before { content: $fa-var-wpbeginner; }
.fa-wpforms:before { content: $fa-var-wpforms; }
.fa-envira:before { content: $fa-var-envira; }
.fa-universal-access:before { content: $fa-var-universal-access; }
.fa-wheelchair-alt:before { content: $fa-var-wheelchair-alt; }
.fa-question-circle-o:before { content: $fa-var-question-circle-o; }
.fa-blind:before { content: $fa-var-blind; }
.fa-audio-description:before { content: $fa-var-audio-description; }
.fa-volume-control-phone:before { content: $fa-var-volume-control-phone; }
.fa-braille:before { content: $fa-var-braille; }
.fa-assistive-listening-systems:before { content: $fa-var-assistive-listening-systems; }
.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before { content: $fa-var-american-sign-language-interpreting; }
.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before { content: $fa-var-deaf; }
.fa-glide:before { content: $fa-var-glide; }
.fa-glide-g:before { content: $fa-var-glide-g; }
.fa-signing:before,
.fa-sign-language:before { content: $fa-var-sign-language; }
.fa-low-vision:before { content: $fa-var-low-vision; }
.fa-viadeo:before { content: $fa-var-viadeo; }
.fa-viadeo-square:before { content: $fa-var-viadeo-square; }
.fa-snapchat:before { content: $fa-var-snapchat; }
.fa-snapchat-ghost:before { content: $fa-var-snapchat-ghost; }
.fa-snapchat-square:before { content: $fa-var-snapchat-square; }
.fa-pied-piper:before { content: $fa-var-pied-piper; }
.fa-first-order:before { content: $fa-var-first-order; }
.fa-yoast:before { content: $fa-var-yoast; }
.fa-themeisle:before { content: $fa-var-themeisle; }
.fa-google-plus-circle:before,
.fa-google-plus-official:before { content: $fa-var-google-plus-official; }
.fa-fa:before,
.fa-font-awesome:before { content: $fa-var-font-awesome; }
