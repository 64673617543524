footer{
  @include grid-container;
  background-image: linear-gradient(103deg, #00d7af, #575bde);
  padding-top:100px;
  padding-bottom:100px;
  position:relative;
  overflow:hidden;

  .eye{
    width:30%;
  }
  .left{
    @include grid-column(5);
    @media screen and (max-width:$smed) {
      @include grid-column(12);
    }
    
    p{
      font-size:16px;
    }
    h3{
      font-size:42px;
      color:$dark-blue;
    }
    cite{
      color:$white;
      font-style:none;
      font-size:9px;
    }
  }
  
  .right{
    @include grid-column(7);
    @media screen and (max-width:$smed) {
      width:100%;
      display: flex;
      flex-direction: column-reverse;
    }
    .logos{
      @include grid-column(4);
      @media screen and (max-width:$smed) {
        @include grid-column(12);
      }
      img{
        margin-bottom:40px;
        @media screen and (max-width:$smed) {
          float:right;
          margin-left:30px;
        }
      }
    }
    .disclaimer{
      @include grid-column(8);
      @media screen and (max-width:$smed) {
        width:100%;
        margin-left:0;
      }
      p{
        font-size:13px;
        font-weight:400;
      }
    }
  }
}