// List Icons
// -------------------------

.fa-ul {
  padding-left: 0;
  margin-left: $fa-li-width;
  list-style-type: none;
  > li { position: relative; }
}
.fa-li {
  position: absolute;
  left: -$fa-li-width;
  width: $fa-li-width;
  top: (2em / 14);
  text-align: center;
  &.fa-lg {
    left: -$fa-li-width + (4em / 14);
  }
}
