// Bordered & Pulled
// -------------------------

.fa-border {
  padding: .2em .25em .15em;
  border: solid .08em $fa-border-color;
  border-radius: .1em;
}

.fa-pull-left { float: left; }
.fa-pull-right { float: right; }

.fa {
  &.fa-pull-left { margin-right: .3em; }
  &.fa-pull-right { margin-left: .3em; }
}

/* Deprecated as of 4.4.0 */
.pull-right { float: right; }
.pull-left { float: left; }

.fa {
  &.pull-left { margin-right: .3em; }
  &.pull-right { margin-left: .3em; }
}
