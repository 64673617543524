// Nav styles
$size : 50px;
$items : 5;
$transition : .5s;
$bounce : cubic-bezier(.3,1.4,.5,.9);
$color-inner : $yellow;
$color-outer : $violet;
$background: mix($color-inner, $color-outer);
// $black : shade($color-outer, 80%);
$black: #26393b;
$start-scale : .5;
$start-rot : 170deg;

nav {
	display: block;
	position: absolute;
	width: $size * $items * 2;
	height: $size * $items * 2;
	user-select: none;
	transform: translate3d($size*.2,-$size*.2,0);
	transition: transform $transition $bounce;
  z-index:99;
	
	&.open {
		transform: translate3d(0,0,0);
    z-index:99999;
	}
	
	&.top-left {
		// top: -$size * ($items - 2.2);
		// left: -$size * ($items - 2.2);
    top:-180px;
    left:-100px;
    @media screen and (max-width:$slarge){
      left:-180px;
    }
    
    @media screen and (max-width:$ssmall){
      left:-200px;
    }
	}
  
  a{
    &:focus,
    &:active,
    &:hover{
      text-decoration:none !important;   
    }
  }
}

.disc {	
	position: absolute;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	font-size: $size*.35;
  color: $white;
  font-family: $h-font;
	line-height: $size * .8;
	padding-top: $size * .2;
	border-radius: $size * $items;
	transform: scale3d($start-scale, $start-scale, $start-scale) rotate3d(0,0,0,$start-rot);
	pointer-events: none;
	opacity: 0;
	cursor: pointer;
	transition: transform $transition $bounce, opacity $transition;
	div {
		transform: rotate(180deg);
	}
	
	.open & {
		pointer-events: auto;
		opacity: 1;	
	}
}

@for $i from 1 through $items {
	.l#{$i} {
		$i : $i - 1;
		$-i : $items - $i;
		$pct : $i/$items * 100%;
		$color :  mix($color-inner, $color-outer, $pct);
		
		top: $i * $size;
		left: $i * $size;
		right: $i * $size;
		bottom: $i * $size;
		background: $color;
		transition-delay: $i * $transition/$items;
    
		.open & {
			transition-delay: $i * $transition/$items;
			transform: scale3d(1,1,1) rotate3d(0,0,1,$start-rot);
			opacity: 1; 
      // transition: background-color .3s ease 0s, color .3s ease 0s;
      
			&:hover {
				background: $black;
				color: $color;
				transition-delay: 0s;
			}
	
			&:active {
				background: shade($color, 50%);
				color: $color;
			}
			
			&.toggle {
				transform: scale3d(.9,.9,.9) rotate3d(0,0,1,$start-rot - 180deg);
			}
		}
	}
}

.toggle {
	line-height: $size*1.5;
	padding: 0;
	width: $size*1.5;
  height: $size*1.5;
	background: $color-inner;
	opacity: 1;
	transform: none;
	pointer-events: auto;
	transition-delay: 0s;
	transition: background-color .3s ease .01s, color .3s ease .01s;
	.open & {
		transform: rotate3d(0,0,1,0deg);
	}

	&:hover {
		background: $black;
		color: $color-inner;
	}
	&:active {
		background: shade($color-inner, 50%);
		color: rgba($color-inner, .5);
		transform: scale(.9);
		transition-duration: 0s;
	}
}