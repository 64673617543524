/*global elements*/
#main,
footer,
header{
  margin:0 auto;
  max-width:$mq-interior-hero-max;
}
.addthis_button_compact {
  display: inline-block;
  margin-top: 30px;
}
.social{
  a{
    display:inline-block;
    margin:10px 10px 10px 0;
    img{
      height:48px;
      width:48px;
    }
  }
}
section .wrapper,
footer,
header{
  padding:0 116px;
  @media screen and (max-width:$slarge){
    padding: 0 30px;
  }
  @media screen and (max-width:$ssmall){
    padding: 0 10px;
  }
}

/*rotating circles*/
  /*do not move into animations file - breaks the animation*/
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
/*clearfloat*/
.clear{
  display:block;
  clear:both;
}
/*styling for both video sliders*/
.video-slider{
  position:relative;
  height:500px;
  margin-bottom:40px;
  overflow:visible;
  @media screen and (max-width:$slarge){
    height:auto !important;
    margin-bottom:30px;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    @media screen and (max-width:$slarge){
      overflow:visible;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width:$slarge){
        display:block;
        margin-top:30px;
      }
      @media screen and (max-width:$ssmall){
        display:block;
      }

      .text{
        @include grid-column(5);
        p{
          clear:both;
          text-align:left;
          height:100%;
        }
        @media screen and (max-width:$slarge){
          @include grid-column(12);
        }
      }
      .video{
        @include grid-column(7);

        height:80%;
        margin-left:0;
        padding:5px;
        @media screen and (max-width:$slarge){
          @include grid-column(12);
          height:auto;
        }
        .container{
          position:relative;
          height:0;
          padding-bottom:56.4%;
          overflow:hidden;
          border-style:solid;
          border-width:5px;
          background-color:$off-black;
          border-image: linear-gradient(to left, #3023ae 1%, #c86dd7 100%);
          border-image-slice: 5;
            @media screen and (max-width:$slarge){
              padding-bottom:50%;
            }
          iframe, object, embed {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
          }
        }
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev{
    position:absolute;
    background-image:none;
    font-size:88px;
    line-height:.8;
    height:auto;
    width:auto;
    padding:0 10px 15px 10px;
    box-shadow: 20px 20px 30px 8px rgba(0,0,0,0.5);
    top:50%;
    transform: translateY(-50%);
    &:focus{
      outline:none;
    }
    @media screen and (max-width:$slarge){
      top:75%;
    }
    @media screen and (max-width:$ssmall){
      top:85%;
    }
  }
  .swiper-button-next{
    background-color:$yellow;
    color:$dark-blue;
    right:-60px;
    @media screen and (max-width:$slarge){
      right:0;
    }
  }
  .swiper-button-prev{
    background-color:$dark-blue;
    color:$yellow;
    left:-60px;
    @media screen and (max-width:$slarge){
      left:0;
    }
  }
}

/*main page*/
#main {
  @include grid-container;
  overflow:hidden;
  position:relative;
  .wrapper{
    width:100%;
    height:100%;
    padding-top:80px;
    background-image:url(../../img/prp.svg);
    background-repeat: no-repeat;
    background-position: top right;
    background-size:160% auto;
    // overflow:hidden;
  }
  .rotate{
    animation: rotation 30s infinite linear;
    position:absolute;
    z-index:1;
    // transform: translate(-50%, -50%);
    &.white{
      top: 3%;
      left: 40%;
    }
    &.purple{
      top: 0;
      left: 25%;
    }
  }
  .eye{
    position:absolute;
    top:-2%;
    left:-10%;
    width:65%;
    z-index:10;
    @media screen and (max-width:$smed){
      width:100%;
      top:1%;
    }
  }
  #intro{
    display:block;
    position:relative;
    background-image:url(../../img/bl.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size:cover;
    // overflow:hidden;

    #intro-text{
      position:relative;
      z-index:99;
      p{
        margin-top:-25px;
        @media screen and (max-width:$ssmall){
          margin-top:0;
        }
      }
    }
  /*first video slider*/
    .video-slider{
      h2{
        &.over{
          font-size:38px;
          @media screen and (max-width:$ssmall){
            font-size:24px;
          }
        }
        &.under{
          font-size:33px;
          @media screen and (max-width:$ssmall){
            font-size:20px;
          }
        }
      }
      p{
        text-align:right;
      }
      @media screen and (max-width:$ssmall){
        margin-top:50px;
      }
    }
    #feeling-targeted{
      position:relative;
      z-index:99;
      @include grid-container;
      position:relative;
      .left{
        @include grid-column(5);
        @media screen and (max-width:$slarge){
          display:none;
        }
        .skull-wrapper{
          border-radius:50%;
          background-color:rgba(255,255,255,.2);
          width:330px;
          height:330px;
          position:relative;
          @media screen and (max-width:$slarge){
            margin:0 auto;
          }
            .skull{
              width:300px;
              height:320px;
            }
            .dashed-circle{
              position:absolute;
              top:0;
              right:0;
            }
            p{
              text-transform:uppercase;
              font-weight:800;
              text-shadow:-5px 5px 0px $dark-violet;
              font-size:32px;
              letter-spacing:4px;
              text-align:center;
              transform: rotate(-15deg);
              position:absolute;
              top: 27%;
              left: 0;
              z-index: 4;
            }
        }
        .butts{
          min-width:551px;
          margin-left:-89px;
          margin-bottom:-22px;
          @media screen and (max-width:$slarge){
            position:absolute;
            bottom:0;
            right:-20%;
            min-width:100%;
            z-index:-1;
            margin-bottom:-40px;
          }
          @media screen and (max-width:$smed){
            position:absolute;
            bottom:0;
            right:-20%;
            min-width:100%;
            z-index:-1;
            margin-bottom:-40px;
          }
          @media screen and (max-width:$ssmall){
            margin-bottom:-22px;
          }
        }
      }
      .right{
        @include grid-column(7);
        padding-top:0px;
        @media screen and (max-width:$slarge){
          @include grid-column(12);
          margin-bottom:100px
        }
        @media screen and (max-width:$smed){
          @include grid-column(12);
          margin-bottom:100px;
        }
        @media screen and (max-width:$ssmall){
          margin-bottom:50px;
        }

      }

      .facts{
        @include grid-container;
        margin-top:30px;
        @media screen and (max-width:$slarge){
          margin-top:10px;
        }
        .icon{
          @include grid-column(3);
          @media screen and (max-width:$slarge){
            @include grid-column(2);
            margin-left: 10%;
          }
          @media screen and (max-width:$smed){
            @include grid-column(4);
            margin-left: 0;
          }
        }
        .desc{
          @include grid-column(8);
          @media screen and (max-width:$slarge){
            @include grid-column(7);
          }
          @media screen and (max-width:$smed){
            @include grid-column(8);
          }
          p{
            font-weight:600;
          }
        }
      }

    }

  }

  #quiz{
    .share-this-quiz {
      display: inline-block;
      background-color: #FFBF2F;
      color: black;
      padding: 10px;
      float: right;
      cursor: pointer;
    }

 //quiz stylings
    .tryAgain {
      float: none;
      margin: 20px 0;
    }
    .quizArea{
      display:none;
      opacity:0;
      @media screen and (max-width:$smed){
        overflow:scroll;
      }

    }
    .quizArea,
    .quizResults{
      margin: 0;
      position: absolute;
      z-index: 9999;
      top: 0;
      left: 0;
      padding:30px;
      background-color: rgba(0,0,0,.9);
      height: 100%;
      width:100%;
      transition:.5s ease;
      &.active{
        display:block;
        opacity:1;
      }
      &:before,
      &:after{
        content: "\0020";
        display: block;
        height: 0;
        visibility: hidden;
        font-size: 0;
      }
      &:after{
        clear:both;
      }
      h3{
        margin-bottom:30px;
        @media screen and (max-width:$smed){
          font-size:32px;
        }
      }
    }
    .questionCount {
        font-size: 14px;
        font-style: italic;
        margin-bottom:20px;
        span{
          font-weight:700;
        }
    }
    .quizLevel{
      span{
        display:block;
        color: #fff;
        font-family: $font;
        font-size: 20px;
        line-height: 1.5em;
        margin-top: 30px;
        font-weight: 600;
      }
    }
    ol,
    ul{
      list-style-type: none;
    }
    ol{
      &.questions{
        li{
          margin-left: 0;
          &.question{

          }

        }
      }
    }

    ul{
    padding-inline-start:0;
    padding-left:0;
      &.answers{
        margin-left: 0;
        margin-bottom: 20px;
      }
      &.responses{
        li{
          margin: 0;
          p{
            padding-right:120px;
            span{
              display: block;
              font-weight: bold;
              font-size: 18px;
            }
          }
        }
      }
    }

    .quizResultsCopy {
        clear: both;
        margin-top: 20px;
    }

    .startQuiz,
    .nextQuestion,
    .backToQuestion,
    .questions li.question,
    .questions li.question .responses,
    .questions li.question .responses .correct,
    .questions li.question .responses .incorrect,
    .quizResults {
        display: none;
    }
    .nextQuestion.checkAnswer {
        display: block;
    }
    .button{
      &.nextQuestion,
      &.checkAnswer{
        background-color: $yellow;
        color:$off-black;
        padding:15px 30px;
        position:absolute;
        right:30px;
        bottom:30px;
      }

    }
    /* Accessibility */
    .quizName span:first-child {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

 //end quiz stylings

    background-image:url(../../img/quiz-bg.png);
    background-repeat:no-repeat;
    background-size:cover;
    background-position:top center;
    position:relative;
    .wrapper{
      @include grid-container;
      background:rgba(255,255,255,.65);
      padding-top:120px;
      padding-bottom:120px;
      background-size:100%;
      @media screen and (max-width:$ssmall){
        padding-top:60px;
      }
      .left{
        @include grid-column(5);
        text-align:right;
        @media screen and (max-width:$ssmall){
          @include grid-column(12);
          text-align:center;
        }

        p{
          color:$light-violet;
          font-weight:700;
        }
      }
      .middle{
        @include grid-column(3);
        padding-left:80px;
        @media screen and (max-width:$ssmall){

          @include grid-column(12);
          padding:0;
          height:250px;
        }

        .go-button{
          height:250px;
          width:250px;
          position:absolute;
          top:50%;
          left:50%;
          z-index:999;
          transform: translateY(-50%);
          @media screen and (max-width:$ssmall){
            position:relative;
            display:block;
            margin:50% auto;
            top:0;
            left:0;
          }
        }
        .dashed-line{
          position: absolute;
          left: 20%;
          top: -10%;
          z-index:99;
          transform: translateY(-50%);
          @media screen and (max-width:$slarge){
            display:none;
          }
        }
        .dashed-line-straight{
          display:none;
          @media screen and (max-width:$ssmall){

            display:block;
          }
        }
      }
      .right{
        @include grid-column(4);
        @media screen and (max-width:$slarge){
          display:none;
          @include grid-column(12);
        }
        .woman{
          position:absolute;
          bottom:0;
          right:0;

        }
      }
    }
  }

  #flavor-trap{
    background-image:url(../../img/cottoncandy.jpg);
    background-repeat:no-repeat;
    background-size:cover;
    background-position:bottom center;
    position:relative;
    overflow:hidden;
    .rotate{
      position:absolute;
      transform: translate(-50%, -50%);
      &.left{
        bottom: 0;
        left: 0;
      }
      &.right{
        top: 20%;
        right: 0;
      }
    }
    .wrapper{
      background-image:url(../../img/prp.svg);
      background-repeat:no-repeat;
      background-size:200%;
      background-position:top left;

      .candy-facts{
        @include grid-container;

        .monster{
          @include grid-column(9);
          @media screen and (max-width:$smed){

            @include grid-column(12);
          }

          .candy-monster{
            position:relative;
            z-index:9999;
            margin-top:-150px;
            margin-left:-120px;
            @media screen and (max-width:$smed){

              display:block;
              margin-top:-50px;
              margin-left:0;
            }
          }
        }
        .fact{
          @include grid-column(3);
          float:right;
          text-align:right;
          margin-bottom:60px;
          z-index:999;
          position:relative;
          @media screen and (max-width:$smed){

            @include grid-column(12);
          }
          h3{
            color:$light-blue;
          }
          h4{
            color:$yellow;
            span{
              font-size:52px;
              display:block;
            }
          }
        }
      }

    }
  }

  #poison{
    @include grid-container;
    background-image: linear-gradient(to bottom, #51ecc7, #217893);
    .wrapper{
      background:none;
    }
    .description{
      @include grid-column(9);
      margin-left:0;
      @media screen and (max-width:$smed){
        @include grid-column(12);
      }
      .text{
        @include grid-column(7);
        display:inline-block;
        vertical-align:middle;
        float:none;
        margin-left:0;
        @media screen and (max-width:$slarge){
          @include grid-column(9);
          margin-left:0;
        }
        @media screen and (max-width:$smed){
          @include grid-column(12);
        }
        h2{
          font-size:38px;
          @media screen and (max-width:$ssmall){
            font-size:24px;
          }
        }
        &.small{
          // @include grid-column(5);
          margin-left:0;
          float:none;
          width:40%;
          @media screen and (max-width:$slarge){
            width:100%;
          }
        }
      }
    }
    /*carousel*/
    .swiper-container {
      width: 100%;
      height: 100%;
      margin:20px 0 30px 0;
    }
    .gallery-top {
      @include grid-column(3);
      margin-left:0;
      margin-top:0;
      float:right;
      @media screen and (max-width:$smed){

        float:left;
        @include grid-column(12);
      }
      .swiper-slide{
        opacity:0;
        background-color:#afe2b2;
        border-radius:10px;
        @media screen and (max-width:$smed){
          max-width:50%;
          margin-left:25%;
        }
        @media screen and (max-width:$ssmall){
          max-width:100%;
          margin-left:auto;
        }
        &.swiper-slide-active{
          opacity:1;
        }
        img{
          display:block;
          margin:10px auto 20px auto;
          max-height:150px;
        }
        p{
          color:$off-black;
          font-weight:600;
          font-size:26px;
          text-align:center;
          margin:0;
          padding:15px 5px;
          &.ele-desc{

          }
          &.ele-name{
            background-color:$pale-blue;
            border-bottom-right-radius:10px;
            border-bottom-left-radius:10px;

          }
        }
      }
    }

    .gallery-thumbs {
      height: auto;
      width:70%;
      box-sizing: border-box;
      padding:0;
      @media screen and (max-width:$smed){
        width:100%;
      }

      .swiper-slide {
        width: 18% !important;
        height: 100%;
        margin-left:10px;
        background-color:$pale-blue;
        border-radius:10px;
        padding-top:5px;
        cursor:pointer;
        @media screen and (max-width:$slarge){
          display:block;
          margin-left:10px;
          width: 18% !important;
        }
        @media screen and (max-width:$ssmall){
          width: 16% !important;
        }

        p{
          font-weight:600;
          color:$off-black;
          display: inline-block;
          width: 100%;
          height: 100%;
          padding-left: 5px;
          padding-right:5px;
          margin:0;

          &.ele-id{
            text-align:left;
            padding-bottom:15px;
            @media screen and (max-width:$smed){

              padding-bottom:5px;
            }
          }
          &.element{
            font-size:60px;
            text-align:center;
            @media screen and (max-width:$smed){

              font-size:26px;
            }
          }
          &.ele-num{
            text-align:center;
            @media screen and (max-width:$smed){

              font-size:10px;
            }

          }
          &.ele-name{
            text-align:center;
            padding-top:10px;
            padding-bottom:10px;
            border-bottom-right-radius:10px;
            border-bottom-left-radius:10px;
            @media screen and (max-width:$smed){

              font-size:9px;
            }
            &.green{
              background-color:#77DD88;
            }
            &.blue{
              background-color:#99DDCC;
            }
            &.pink{
              background-color:#DDBBBB;
            }
          }
        }
      }
      .swiper-slide-thumb-active {
        display:none;
      }
    }
  }

  #vape{
    // background-image:url(../../img/boys.png);
    background-repeat:no-repeat;
    background-size:100%;
    background-position:bottom left;
    background:linear-gradient(90deg, rgba(221,205,27,1) 0%, rgba(231,69,167,1) 100%);

    .wrapper{
      padding-top:60px;
      padding-bottom:60px;
      // background-image:url(../../img/or-pk.svg);
      background-repeat:no-repeat;
      background-size:cover;
      background-position:top left;

      .top-half{
        @include grid-container;

        .left{
          @include grid-column(5);
          position:relative;
          z-index:10;
          @media screen and (max-width:$slarge){
            @include grid-column(12);
            padding-left:30px;
            padding-right:30px;
          }
        }
        .right{
          @include grid-column(7);
          @media screen and (max-width:$slarge){
            @include grid-column(12);
          }
          img{
            height:340px;
            margin-top:-65px;
            @include grid-column(7);
            @media screen and (max-width:$smed){
              @include grid-column(12);
              margin-top:-15px;
            }
          }
          p{
            display:inline-block;
            @include grid-column(5);
              @media screen and (max-width:$smed){
                @include grid-column(12);
              }
            span{
              color:$yellow;
              @media screen and (max-width:$ssmall){
                color:$violet;
              }
            }
          }
        }
      }
    }

    .bottom-half{
      @include grid-container;
      margin-top:140px;
      @media screen and (max-width:$ssmall){

        margin-top:40px;
      }

      .left{
        @include grid-column(7);
        @media screen and (max-width:$ssmall){

          @include grid-column(12);
        }

        h3, h4, img{
          display:inline-block;
          vertical-align:bottom;
        }
        .float{
          display:inline-block;
        }
        h4{
          color:$light-violet;
          span{
            font-size:32px;
          }
        }
        h3{
          color:$fuchsia;
        }

      }


      .right{
        @include grid-column(5);
        @media screen and (max-width:$ssmall){

          @include grid-column(12);
            display:none;
          }
        background-image:url(../../img/vapor.svg);
        background-size:contain;
        background-repeat:no-repeat;
        background-position: top right;
        p{
          text-align:right;
          width:75%;
          float:right;
        }
      }
    }

  }

  #facts{
    max-height:600px;
    overflow:hidden;
    /*round slider*/
    @media screen and (max-width:$slarge){
      max-height:500px;
    }
    @media screen and (max-width:$smed){
      max-height:475px;
    }
    @media screen and (max-width:$ssmall){
      max-height:375px;
    }
    .wrapper{
      background-image: linear-gradient(to bottom, #2369ae, #53a0fd 48%, #51dcec);
      padding-top:30px;
    }
    .wheel {
      width: 80%;
      height: auto;
      display: flex;
      align-items:center;
      justify-content: space-between;
      margin:0 auto;
      @media screen and (max-width:$slarge){
        margin:80px auto 0 auto;
        width:100%;
      }
      @media screen and (max-width:$smed){
        margin:80px -60px 0 auto;
      }
      @media screen and (max-width:$ssmall){
        margin:80px 35px 0 auto;
        width:80%;
      }

      > * {
        flex-grow: 1;
      }
    }

    .circle--rotate {
      border-radius: 50%;
      border: 10px #fff dashed;
      width: 100%;
      height: 100%;
      margin: 0 auto 0;
      position: relative;
      transform: rotate(0deg);
      z-index: 0;
      padding: 0;
      @media screen and (max-width:$slarge){
        width:80%;
        height:80%;
      }
      @media screen and (max-width:$smed){
        width:500px;
        height:500px;
      }
      @media screen and (max-width:$ssmall){
        width:400px;
        height:400px;
      }

      > li {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 50%;
        z-index: 10;
        visibility: hidden;
        @media screen and (max-width:$smed){
          width:500px;
          height:500px;
        }
        @media screen and (max-width:$ssmall){
          width:400px;
          height:400px;
        }

        div {
          visibility: visible;
          cursor: pointer;
          text-align: center;
          overflow: hidden;
          font-size: 20px;
          color: $off-black;
          border-radius: 50%;
          background-color: $white;
          height: 300px;
          width: 300px;
          margin: -10% auto;
          transition: all 500ms ease;
          @media screen and (max-width:$slarge){
            height:200px;
            width:200px;
          }
          @media screen and (max-width:$smed){
            height:150px;
            width:150px;
          }
          @media screen and (max-width:$ssmall){

            height:100px;
            width:100px;
          }
          &:hover {
          }
          &:before{
            border-radius: 100%;
            content: '';
            background-image: linear-gradient(87deg, #e9ce00, #eb11dc);
            padding: 8px;
            width: 300px;
            height:300px;
            top: -8px;
            left: -8px;
            position:absolute;
            z-index:-1;
            @media screen and (max-width:$slarge){
              height:200px;
              width:200px;
            }
            @media screen and (max-width:$smed){
              height:150px;
              width:150px;
            }
            @media screen and (max-width:$ssmall){
              height:100px;
              width:100px;
            }
          }
          img{
            padding:25px;
          }
          p{
            display:block;
            position:absolute;
            right:0;
            font-size:16px;
            @media screen and (max-width:$slarge){
              @include grid-column(12);
              width:350px;
              margin: 20% -35% 0 0;
              font-size:14px;
            }
            @media screen and (max-width:$smed){
              @include grid-column(12);
              width:250px;
              margin: 20% -35% 0 0;
              font-size:14px;
            }
            @media screen and (max-width:$ssmall){
              @include grid-column(12);
              width:250px;
              margin: 20% -75% 0 0;
              font-size:14px;
            }
          }
        }
      }
    }

    .circle--slider {
      position: relative;
      @media screen and (max-width:$smed){
        margin-left:-20%;
      }

      .rotate--circle {
        margin: 0 auto;
      }

      .circle--rotate > li {

        div {
          position: relative;
          overflow: visible;
          background-color: $white;
          p{
            opacity:0;
            transition:1s ease;
          }
        }

        &.active div {
          font-size: 20px;
          background-color: $white;
          transition: all 1s;
          p{
            opacity:1;
          }
        }
      }

      .count2 li:nth-child(2) {
        transform: rotate(180deg);
      }

      .count3 li {
        &:nth-child(2) {
          transform: rotate(120deg);
        }
        &:nth-child(3) {
          transform: rotate(240deg);
        }
      }

      .count4 li {
        &:nth-child(2) {
          transform: rotate(90deg);
        }
        &:nth-child(3) {
          transform: rotate(180deg);
        }
        &:nth-child(4) {
          transform: rotate(270deg);
        }
      }

      .count5 li {
        &:nth-child(2) {
          transform: rotate(72deg);
        }
        &:nth-child(3) {
          transform: rotate(144deg);
        }
        &:nth-child(4) {
          transform: rotate(216deg);
        }
        &:nth-child(5) {
          transform: rotate(-72deg);
        }
      }
      .prev,
      .next {
        position:absolute;
        cursor:pointer;
        font-size:88px;
        line-height:.8;
        height:auto;
        width:auto;
        padding:0 10px 15px 10px;
        box-shadow: 20px 20px 30px 8px rgba(0,0,0,0.5);
        top:20%;
        transform: translateY(-50%);
        margin: auto;
        display: block;

         span {
          writing-mode: vertical-rl;
          text-orientation: upright;
          font-weight: 700;
        }
      }

      .prev {
        background-color:$dark-blue;
        color:$yellow;
        left:-180px;
        @media screen and (max-width:$slarge){
          left:3%;
        }
        @media screen and (max-width:$smed){
          left:10%;
        }
        @media screen and (max-width:$ssmall){
          left:20px;
        }
      }

      .next {
        background-color:$yellow;
        color:$dark-blue;
        right:-180px;
        @media screen and (max-width:$slarge){
          right:3%;
        }
        @media screen and (max-width:$smed){
          right:10%;
        }
        @media screen and (max-width:$ssmall){
          right:20px;
        }
      }
    }
  }
}

.st-custom-button {
  cursor: pointer;
}