// ==================================================
// Brand
// ==================================================

/*colors*/
$white: #fff;
$yellow: #FFBF2F;
$dark-violet: #6208C5;
$light-violet: #B02FB2;
$violet: #971BEC;
$fuchsia: #FF00FF;
$pale-pink: #d1ceb9;
$pale-blue: #d5eded;
$light-blue: #5BCCDE;
$dark-blue: #575BDE;
$off-black: #26393b;

$color-white: #fff;
$brand: #ccc;
$brand-grey: #3e4751;
$brand-red: #ed1c24;
$brand-dark-blue: #092f48;
$brand-light-blue: #c0ced6;
$brand-medium-blue: #1b3e55;
$brand-modal-hover: rgba(0,0,0,.5);

$selection: $brand;
$highlight: #ffc;
$bg-body: $off-black;
$content-area: #fff;

$modal-overlay-background: hsla(0, 0%, 0%, 0.5);
$modal-wrapper-shadow: hsla(0, 0%, 0%, 0.35);


// ==================================================
// Animations
// ==================================================
$base-duration: 150ms;
$base-timing: ease;

// ==================================================
// Grid information
// ==================================================
$max-grid-width: 1480px;

//breakpoints
$sfull: 1440px;
$slarge: 1200px;
$smed: 768px;
$ssmall: 576px;
// ==================================================
// Typography
// ==================================================
$font: 'Montserrat', sans-serif;
$h-font: 'Ultra', serif;

$container-padding: 20px;

$h1-size: 120px;
$h1-size-tablet: 19vw;
$h2-size: 43px;
$h3-size: 42px;
$h3-size-tablet: 5vw;
$h4-size: 24pt;
$h5-size: 24px;
$h6-size: 1.4em;

$h1-text: $brand-grey;
$h2-text: $white;
$h3-text: $yellow;
$h4-text: $dark-violet;
$h5-text: $brand-grey;
$h6-text: $brand-grey;

$h1-link: $brand-grey;
$h2-link: $brand-grey;
$h3-link: $brand-grey;
$h4-link: $brand-grey;
$h5-link: $brand-grey;
$h6-link: $brand-grey;

$h1-link-hover: $brand-grey;
$h2-link-hover: $brand-grey;
$h3-link-hover: $brand-grey;
$h4-link-hover: $brand-grey;
$h5-link-hover: $brand-grey;
$h6-link-hover: $brand-grey;

$font-normal: 500;
$font-bold: 700;
$font-extra-bold: 900;

$p-text: $white;
$p-size: 20px;

$link: #092f48;
$link-hover: #092f48;

$rule: $brand-grey;

$blockquote: #444;

$table-bg: #fff;
$table-bg-hover: darken($table-bg, 10%);
$td-text: #444;
$th-text: $brand-grey;

// ==================================================
// Buttons
// ==================================================
$button-bg: #eee;
$button-bg-focus: #e5e5e5;

// ==================================================
// Forms
// ==================================================
$valid: none;
$invalid: #f0dddd; // red

$input-bg: #fff;
$input-border: #ccc;
$input-color: #a4a4a4;
$label-color: #444;

$input-size: 1.2em;
