@charset "UTF-8";
/*fonts*/
/*colors*/
* {
  box-sizing: border-box;
}

::selection {
  text-shadow: none;
  background: #ccc;
  color: #3e4751;
}

html,
body {
  background-color: #26393b;
  height: 100%;
  margin: 0;
  min-height: 100%;
  position: relative;
}

body {
  color: #ccc;
  font: 16px/24px "Montserrat", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: #26393b;
  height: auto;
}

body.scroll {
  overflow: hidden;
}

p:empty {
  display: none;
}


.container {
  margin: 0 auto;
  max-width: 1480px;
}


.container::after {
  clear: both;
  content: "";
  display: block;
}


.row {
  float: left;
  width: 100%;
  clear: both;
}

.firstcol {
  margin-left: 0;
}

.lastcol {
  margin-right: 0;
}


.col1 {
  width: calc(8.33333% - 21.66667px);
  float: left;
  margin-left: 20px;
}


.col2 {
  width: calc(16.66667% - 23.33333px);
  float: left;
  margin-left: 20px;
}


.col3 {
  width: calc(25% - 25px);
  float: left;
  margin-left: 20px;
}


.col4 {
  width: calc(33.33333% - 26.66667px);
  float: left;
  margin-left: 20px;
}


.col5 {
  width: calc(41.66667% - 28.33333px);
  float: left;
  margin-left: 20px;
}


.col6 {
  width: calc(50% - 30px);
  float: left;
  margin-left: 20px;
}


.col7 {
  width: calc(58.33333% - 31.66667px);
  float: left;
  margin-left: 20px;
}


.col8 {
  width: calc(66.66667% - 33.33333px);
  float: left;
  margin-left: 20px;
}


.col9 {
  width: calc(75% - 35px);
  float: left;
  margin-left: 20px;
}


.col10 {
  width: calc(83.33333% - 36.66667px);
  float: left;
  margin-left: 20px;
}


.col11 {
  width: calc(91.66667% - 38.33333px);
  float: left;
  margin-left: 20px;
}


.col12 {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
}

@media only screen and (max-width: 768px) {
  
  .col1,
  .col10,
  .col11,
  .col12,
  .col2,
  .col3,
  .col4,
  .col5,
  .col6,
  .col7,
  .col8,
  .col9 {
    display: block;
    float: none;
    margin-left: 0;
    margin-right: 0;
    padding: 20px;
    position: relative;
    width: 100%;
  }
}


.clearfix,
.row {
  zoom: 1;
}


.clearfix::after,
.row::after {
  clear: both;
}


.clearfix::after,
.clearfix::before,
.row::after,
.row::before {
  content: '\0020';
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  width: 0;
}

fieldset {
  margin-bottom: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-family: "Ultra", serif;
  line-height: 1;
  margin-top: 0;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: inherit;
}

h1 {
  font-size: 120px;
  margin-bottom: 30px;
  font-size: 120px;
  padding-bottom: 17px;
  letter-spacing: -4px;
  background: linear-gradient(127deg, #e9ce00, #eb11dc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 19vw;
  }
}

.path-frontpage h1.page-title {
  display: none;
}

h1 a {
  color: #3e4751;
}

h1 a:hover {
  color: #3e4751;
}


h2 {
  display: inline-block;
  color: #fff;
  font-size: 43px;
  margin-bottom: 10px;
  background: linear-gradient(90deg, rgba(16, 115, 228, 0.8) 0%, rgba(83, 160, 253, 0.8) 48.82%, rgba(235, 38, 196, 0.8) 100%);
  padding: 10px;
  letter-spacing: 2px;
  box-shadow: 20px 33px 50px 8px rgba(0, 0, 0, 0.5);
}


h2.over {
  z-index: 99;
  position: relative;
  float: left;
}

@media screen and (max-width: 576px) {
  
  h2.over {
    font-size: 24px;
  }
}


h2.under {
  font-size: 38px;
  float: left;
  margin-left: 80px;
}

@media screen and (max-width: 576px) {
  
  h2.under {
    font-size: 22px;
    position: relative;
    z-index: 999;
  }
}


h2.black {
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.6);
  margin-left: -60px;
}

@media screen and (max-width: 1200px) {
  
  h2.black {
    margin-left: -25%;
    margin-top: 60px;
  }
}

@media screen and (max-width: 768px) {
  
  h2.black {
    margin-left: 25px;
    margin-top: 0;
    font-size: 32px;
  }
}


h2 a {
  color: #3e4751;
}


h2 a:hover {
  color: #3e4751;
}

h2 + img.alignright,
h2 + img.alignleft,
h2 + img.alignnone,
h2 + img.aligncenter {
  margin-top: 0;
}

h2 + img + p {
  margin-top: 0;
}

h3 {
  color: #FFBF2F;
  font-size: 42px;
  margin-bottom: 8px;
}

@media screen and (max-width: 576px) {
  h3 {
    font-size: 5vw;
  }
}

h3 a {
  color: #3e4751;
}

h3 a:hover {
  color: #3e4751;
}

h3 + img.alignright,
h3 + img.alignleft,
h3 + img.alignnone,
h3 + img.aligncenter {
  margin-top: 10px;
}

h4 {
  color: #6208C5;
  font-size: 24pt;
  margin-bottom: 4px;
}

h4 a {
  color: #3e4751;
}

h4 a:hover {
  color: #3e4751;
}

h5 {
  color: #ccc;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 15px;
}

h5 a {
  color: #3e4751;
}

h5 a:hover {
  color: #ccc;
}

h6 {
  color: #3e4751;
  font-size: 1.4em;
}

h6 a {
  color: #3e4751;
}

h6 a:hover {
  color: #3e4751;
}

p {
  color: #fff;
  font-size: 20px;
  line-height: 1.5em;
  margin-bottom: 20px;
  font-weight: 600;
}

p img {
  margin: 0;
}

p + img.alignright,
p + img.alignleft,
p + img.alignnone,
p + img.aligncenter {
  margin-top: 0;
}

hr {
  border: 1px solid #3e4751;
  border-top: 1px solid #3e4751;
  clear: both;
  height: 0;
  margin: 10px 0;
}

code,
pre {
  font-size: 15px;
}

blockquote {
  border-left: 10px solid #444;
  font-style: italic;
  color: #444;
  padding-left: 15px;
}

nav ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

ol {
  list-style: decimal;
}

ul.none {
  list-style: none;
}

ul.square {
  list-style: square outside;
}

ul.no-bull {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #1b3e55;
}

ul.no-bull em {
  margin-left: 40px;
  font-size: 12px;
  display: inline-block;
}

ul.circle {
  list-style: circle outside;
}

ul.disc {
  list-style: disc outside;
}

ul.paragraph-list {
  padding: 0;
}

ul.paragraph-list li {
  margin: 0;
  list-style-type: none;
}

ul li {
  line-height: 1.5;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 20px;
}

dl dt {
  font-weight: bold;
  line-height: 1.2em;
}

dl dd {
  margin: 0 0 10px;
}

a,
a:visited {
  color: #092f48;
  outline: 0;
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:focus {
  color: #092f48;
  text-decoration: underline;
}

li a,
li a:visited,
p a,
p a:visited {
  line-height: inherit;
  font-weight: 700;
}

em,
i {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: 1.1em;
}

sub,
sup {
  font-size: .9em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img,
iframe,
object,
video {
  border: 0;
  height: auto;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
}

img {
  display: block;
}

iframe {
  width: 100%;
  height: 100%;
}

table {
  border-collapse: collapse;
  font-size: 20px;
  text-align: left;
  width: 100%;
}

table th {
  color: #3e4751;
  font-weight: bold;
  padding: 2px 8px;
  text-transform: uppercase;
}

table td {
  border-top: 1px dotted #3e4751;
  color: #444;
  padding: 2px 8px;
}

.ir {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -999em;
}

.irbr {
  display: none;
}

.hidden {
  display: none;
  visibility: hidden;
}

.dead-link {
  pointer-events: none;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clear {
  clear: both;
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  width: 0;
}

.clearfix::before, .clearfix::after,
.row::before,
.row::after {
  content: '\0020';
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  width: 0;
}

i {
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: left center;
  display: inline-block;
  margin: 0 15px -5px 0;
}

header {
  height: 100px;
  padding: 1em 2em;
  position: relative;
  background-image: linear-gradient(95deg, #00d7af, #575bde);
}

header .social {
  padding-top: 15px;
  padding-left: 30px;
  position: relative;
  z-index: 999;
  margin-left: 100px;
}

header .social a {
  display: inline-block;
}

nav {
  display: block;
  position: absolute;
  width: 500px;
  height: 500px;
  user-select: none;
  transform: translate3d(10px, -10px, 0);
  transition: transform 0.5s cubic-bezier(0.3, 1.4, 0.5, 0.9);
  z-index: 99;
}

nav.open {
  transform: translate3d(0, 0, 0);
  z-index: 99999;
}

nav.top-left {
  top: -180px;
  left: -100px;
}

@media screen and (max-width: 1200px) {
  nav.top-left {
    left: -180px;
  }
}

@media screen and (max-width: 576px) {
  nav.top-left {
    left: -200px;
  }
}

nav a:focus, nav a:active, nav a:hover {
  text-decoration: none !important;
}

.disc {
  position: absolute;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 17.5px;
  color: #fff;
  font-family: "Ultra", serif;
  line-height: 40px;
  padding-top: 10px;
  border-radius: 250px;
  transform: scale3d(0.5, 0.5, 0.5) rotate3d(0, 0, 0, 170deg);
  pointer-events: none;
  opacity: 0;
  cursor: pointer;
  transition: transform 0.5s cubic-bezier(0.3, 1.4, 0.5, 0.9), opacity 0.5s;
}

.disc div {
  transform: rotate(180deg);
}

.open .disc {
  pointer-events: auto;
  opacity: 1;
}

.l1 {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #971bec;
  transition-delay: 0s;
}

.open .l1 {
  transition-delay: 0s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 170deg);
  opacity: 1;
}

.open .l1:hover {
  background: #26393b;
  color: #971bec;
  transition-delay: 0s;
}

.open .l1:active {
  background: #4c0e76;
  color: #971bec;
}

.open .l1.toggle {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -10deg);
}

.l2 {
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
  background: #ac3cc6;
  transition-delay: 0.1s;
}

.open .l2 {
  transition-delay: 0.1s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 170deg);
  opacity: 1;
}

.open .l2:hover {
  background: #26393b;
  color: #ac3cc6;
  transition-delay: 0s;
}

.open .l2:active {
  background: #561e63;
  color: #ac3cc6;
}

.open .l2.toggle {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -10deg);
}

.l3 {
  top: 100px;
  left: 100px;
  right: 100px;
  bottom: 100px;
  background: #c15da0;
  transition-delay: 0.2s;
}

.open .l3 {
  transition-delay: 0.2s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 170deg);
  opacity: 1;
}

.open .l3:hover {
  background: #26393b;
  color: #c15da0;
  transition-delay: 0s;
}

.open .l3:active {
  background: #612f50;
  color: #c15da0;
}

.open .l3.toggle {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -10deg);
}

.l4 {
  top: 150px;
  left: 150px;
  right: 150px;
  bottom: 150px;
  background: #d57d7b;
  transition-delay: 0.3s;
}

.open .l4 {
  transition-delay: 0.3s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 170deg);
  opacity: 1;
}

.open .l4:hover {
  background: #26393b;
  color: #d57d7b;
  transition-delay: 0s;
}

.open .l4:active {
  background: #6b3f3e;
  color: #d57d7b;
}

.open .l4.toggle {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -10deg);
}

.l5 {
  top: 200px;
  left: 200px;
  right: 200px;
  bottom: 200px;
  background: #ea9e55;
  transition-delay: 0.4s;
}

.open .l5 {
  transition-delay: 0.4s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 170deg);
  opacity: 1;
}

.open .l5:hover {
  background: #26393b;
  color: #ea9e55;
  transition-delay: 0s;
}

.open .l5:active {
  background: #754f2b;
  color: #ea9e55;
}

.open .l5.toggle {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -10deg);
}

.toggle {
  line-height: 75px;
  padding: 0;
  width: 75px;
  height: 75px;
  background: #FFBF2F;
  opacity: 1;
  transform: none;
  pointer-events: auto;
  transition-delay: 0s;
  transition: background-color .3s ease .01s, color .3s ease .01s;
}

.open .toggle {
  transform: rotate3d(0, 0, 1, 0deg);
}

.toggle:hover {
  background: #26393b;
  color: #FFBF2F;
}

.toggle:active {
  background: #806018;
  color: rgba(255, 191, 47, 0.5);
  transform: scale(0.9);
  transition-duration: 0s;
}

footer {
  background-image: linear-gradient(103deg, #00d7af, #575bde);
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
}

footer::after {
  clear: both;
  content: "";
  display: block;
}

footer .eye {
  width: 30%;
}

footer .left {
  width: calc(41.66667% - 28.33333px);
  float: left;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  footer .left {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

footer .left p {
  font-size: 16px;
}

footer .left h3 {
  font-size: 42px;
  color: #575BDE;
}

footer .left cite {
  color: #fff;
  font-style: none;
  font-size: 9px;
}

footer .right {
  width: calc(58.33333% - 31.66667px);
  float: left;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  footer .right {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
}

footer .right .logos {
  width: calc(33.33333% - 26.66667px);
  float: left;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  footer .right .logos {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

footer .right .logos img {
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  footer .right .logos img {
    float: right;
    margin-left: 30px;
  }
}

footer .right .disclaimer {
  width: calc(66.66667% - 33.33333px);
  float: left;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  footer .right .disclaimer {
    width: 100%;
    margin-left: 0;
  }
}

footer .right .disclaimer p {
  font-size: 13px;
  font-weight: 400;
}

.share-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20000;
  bottom: 0;
}

.share-modal .share-modal-overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
}

.share-modal .share-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  min-height: 50px;
  padding: 40px;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .share-modal .share-container {
    transform: none;
    left: 0;
    right: 0;
  }
}

.share-modal .share-button-container {
  padding: 40px;
}

.share-modal .rrssb-buttons {
  height: 50px;
}

.share-modal .rrssb-buttons a {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 0;
}

.share-modal .rrssb-buttons a i {
  font-size: 25px;
  height: 25px;
  width: 25px;
  color: white;
  margin: 0;
}

.share-modal .modal-close-button {
  position: absolute;
  top: -50px;
  right: 0;
  font-size: 40px;
  z-index: 20001;
  cursor: pointer;
}

/*global elements*/
#main,
footer,
header {
  margin: 0 auto;
  max-width: 1480px;
}

.addthis_button_compact {
  display: inline-block;
  margin-top: 30px;
}

.social a {
  display: inline-block;
  margin: 10px 10px 10px 0;
}

.social a img {
  height: 48px;
  width: 48px;
}

section .wrapper,
footer,
header {
  padding: 0 116px;
}

@media screen and (max-width: 1200px) {
  section .wrapper,
  footer,
  header {
    padding: 0 30px;
  }
}

@media screen and (max-width: 576px) {
  section .wrapper,
  footer,
  header {
    padding: 0 10px;
  }
}

/*rotating circles*/
/*do not move into animations file - breaks the animation*/
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/*clearfloat*/
.clear {
  display: block;
  clear: both;
}

/*styling for both video sliders*/
.video-slider {
  position: relative;
  height: 500px;
  margin-bottom: 40px;
  overflow: visible;
}

@media screen and (max-width: 1200px) {
  .video-slider {
    height: auto !important;
    margin-bottom: 30px;
  }
}

.video-slider .swiper-container {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .video-slider .swiper-container {
    overflow: visible;
  }
}

.video-slider .swiper-container .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .video-slider .swiper-container .swiper-slide {
    display: block;
    margin-top: 30px;
  }
}

@media screen and (max-width: 576px) {
  .video-slider .swiper-container .swiper-slide {
    display: block;
  }
}

.video-slider .swiper-container .swiper-slide .text {
  width: calc(41.66667% - 28.33333px);
  float: left;
  margin-left: 20px;
}

.video-slider .swiper-container .swiper-slide .text p {
  clear: both;
  text-align: left;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .video-slider .swiper-container .swiper-slide .text {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

.video-slider .swiper-container .swiper-slide .video {
  width: calc(58.33333% - 31.66667px);
  float: left;
  margin-left: 20px;
  height: 80%;
  margin-left: 0;
  padding: 5px;
}

@media screen and (max-width: 1200px) {
  .video-slider .swiper-container .swiper-slide .video {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    height: auto;
  }
}

.video-slider .swiper-container .swiper-slide .video .container {
  position: relative;
  height: 0;
  padding-bottom: 56.4%;
  overflow: hidden;
  border-style: solid;
  border-width: 5px;
  background-color: #26393b;
  border-image: linear-gradient(to left, #3023ae 1%, #c86dd7 100%);
  border-image-slice: 5;
}

@media screen and (max-width: 1200px) {
  .video-slider .swiper-container .swiper-slide .video .container {
    padding-bottom: 50%;
  }
}

.video-slider .swiper-container .swiper-slide .video .container iframe, .video-slider .swiper-container .swiper-slide .video .container object, .video-slider .swiper-container .swiper-slide .video .container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-slider .swiper-button-next,
.video-slider .swiper-button-prev {
  position: absolute;
  background-image: none;
  font-size: 88px;
  line-height: .8;
  height: auto;
  width: auto;
  padding: 0 10px 15px 10px;
  box-shadow: 20px 20px 30px 8px rgba(0, 0, 0, 0.5);
  top: 50%;
  transform: translateY(-50%);
}

.video-slider .swiper-button-next:focus,
.video-slider .swiper-button-prev:focus {
  outline: none;
}

@media screen and (max-width: 1200px) {
  .video-slider .swiper-button-next,
  .video-slider .swiper-button-prev {
    top: 75%;
  }
}

@media screen and (max-width: 576px) {
  .video-slider .swiper-button-next,
  .video-slider .swiper-button-prev {
    top: 85%;
  }
}

.video-slider .swiper-button-next {
  background-color: #FFBF2F;
  color: #575BDE;
  right: -60px;
}

@media screen and (max-width: 1200px) {
  .video-slider .swiper-button-next {
    right: 0;
  }
}

.video-slider .swiper-button-prev {
  background-color: #575BDE;
  color: #FFBF2F;
  left: -60px;
}

@media screen and (max-width: 1200px) {
  .video-slider .swiper-button-prev {
    left: 0;
  }
}

/*main page*/
#main {
  overflow: hidden;
  position: relative;
}

#main::after {
  clear: both;
  content: "";
  display: block;
}

#main .wrapper {
  width: 100%;
  height: 100%;
  padding-top: 80px;
  background-image: url(../../img/prp.svg);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 160% auto;
}

#main .rotate {
  animation: rotation 30s infinite linear;
  position: absolute;
  z-index: 1;
}

#main .rotate.white {
  top: 3%;
  left: 40%;
}

#main .rotate.purple {
  top: 0;
  left: 25%;
}

#main .eye {
  position: absolute;
  top: -2%;
  left: -10%;
  width: 65%;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  #main .eye {
    width: 100%;
    top: 1%;
  }
}

#main #intro {
  display: block;
  position: relative;
  background-image: url(../../img/bl.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /*first video slider*/
}

#main #intro #intro-text {
  position: relative;
  z-index: 99;
}

#main #intro #intro-text p {
  margin-top: -25px;
}

@media screen and (max-width: 576px) {
  #main #intro #intro-text p {
    margin-top: 0;
  }
}

#main #intro .video-slider h2.over {
  font-size: 38px;
}

@media screen and (max-width: 576px) {
  #main #intro .video-slider h2.over {
    font-size: 24px;
  }
}

#main #intro .video-slider h2.under {
  font-size: 33px;
}

@media screen and (max-width: 576px) {
  #main #intro .video-slider h2.under {
    font-size: 20px;
  }
}

#main #intro .video-slider p {
  text-align: right;
}

@media screen and (max-width: 576px) {
  #main #intro .video-slider {
    margin-top: 50px;
  }
}

#main #intro #feeling-targeted {
  position: relative;
  z-index: 99;
  position: relative;
}

#main #intro #feeling-targeted::after {
  clear: both;
  content: "";
  display: block;
}

#main #intro #feeling-targeted .left {
  width: calc(41.66667% - 28.33333px);
  float: left;
  margin-left: 20px;
}

@media screen and (max-width: 1200px) {
  #main #intro #feeling-targeted .left {
    display: none;
  }
}

#main #intro #feeling-targeted .left .skull-wrapper {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  width: 330px;
  height: 330px;
  position: relative;
}

@media screen and (max-width: 1200px) {
  #main #intro #feeling-targeted .left .skull-wrapper {
    margin: 0 auto;
  }
}

#main #intro #feeling-targeted .left .skull-wrapper .skull {
  width: 300px;
  height: 320px;
}

#main #intro #feeling-targeted .left .skull-wrapper .dashed-circle {
  position: absolute;
  top: 0;
  right: 0;
}

#main #intro #feeling-targeted .left .skull-wrapper p {
  text-transform: uppercase;
  font-weight: 800;
  text-shadow: -5px 5px 0px #6208C5;
  font-size: 32px;
  letter-spacing: 4px;
  text-align: center;
  transform: rotate(-15deg);
  position: absolute;
  top: 27%;
  left: 0;
  z-index: 4;
}

#main #intro #feeling-targeted .left .butts {
  min-width: 551px;
  margin-left: -89px;
  margin-bottom: -22px;
}

@media screen and (max-width: 1200px) {
  #main #intro #feeling-targeted .left .butts {
    position: absolute;
    bottom: 0;
    right: -20%;
    min-width: 100%;
    z-index: -1;
    margin-bottom: -40px;
  }
}

@media screen and (max-width: 768px) {
  #main #intro #feeling-targeted .left .butts {
    position: absolute;
    bottom: 0;
    right: -20%;
    min-width: 100%;
    z-index: -1;
    margin-bottom: -40px;
  }
}

@media screen and (max-width: 576px) {
  #main #intro #feeling-targeted .left .butts {
    margin-bottom: -22px;
  }
}

#main #intro #feeling-targeted .right {
  width: calc(58.33333% - 31.66667px);
  float: left;
  margin-left: 20px;
  padding-top: 0px;
}

@media screen and (max-width: 1200px) {
  #main #intro #feeling-targeted .right {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 768px) {
  #main #intro #feeling-targeted .right {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 576px) {
  #main #intro #feeling-targeted .right {
    margin-bottom: 50px;
  }
}

#main #intro #feeling-targeted .facts {
  margin-top: 30px;
}

#main #intro #feeling-targeted .facts::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 1200px) {
  #main #intro #feeling-targeted .facts {
    margin-top: 10px;
  }
}

#main #intro #feeling-targeted .facts .icon {
  width: calc(25% - 25px);
  float: left;
  margin-left: 20px;
}

@media screen and (max-width: 1200px) {
  #main #intro #feeling-targeted .facts .icon {
    width: calc(16.66667% - 23.33333px);
    float: left;
    margin-left: 20px;
    margin-left: 10%;
  }
}

@media screen and (max-width: 768px) {
  #main #intro #feeling-targeted .facts .icon {
    width: calc(33.33333% - 26.66667px);
    float: left;
    margin-left: 20px;
    margin-left: 0;
  }
}

#main #intro #feeling-targeted .facts .desc {
  width: calc(66.66667% - 33.33333px);
  float: left;
  margin-left: 20px;
}

@media screen and (max-width: 1200px) {
  #main #intro #feeling-targeted .facts .desc {
    width: calc(58.33333% - 31.66667px);
    float: left;
    margin-left: 20px;
  }
}

@media screen and (max-width: 768px) {
  #main #intro #feeling-targeted .facts .desc {
    width: calc(66.66667% - 33.33333px);
    float: left;
    margin-left: 20px;
  }
}

#main #intro #feeling-targeted .facts .desc p {
  font-weight: 600;
}

#main #quiz {
  /* Accessibility */
  background-image: url(../../img/quiz-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
}

#main #quiz .share-this-quiz {
  display: inline-block;
  background-color: #FFBF2F;
  color: black;
  padding: 10px;
  float: right;
  cursor: pointer;
}

#main #quiz .tryAgain {
  float: none;
  margin: 20px 0;
}

#main #quiz .quizArea {
  display: none;
  opacity: 0;
}

@media screen and (max-width: 768px) {
  #main #quiz .quizArea {
    overflow: scroll;
  }
}

#main #quiz .quizArea,
#main #quiz .quizResults {
  margin: 0;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
  width: 100%;
  transition: .5s ease;
}

#main #quiz .quizArea.active,
#main #quiz .quizResults.active {
  display: block;
  opacity: 1;
}

#main #quiz .quizArea:before, #main #quiz .quizArea:after,
#main #quiz .quizResults:before,
#main #quiz .quizResults:after {
  content: "\0020";
  display: block;
  height: 0;
  visibility: hidden;
  font-size: 0;
}

#main #quiz .quizArea:after,
#main #quiz .quizResults:after {
  clear: both;
}

#main #quiz .quizArea h3,
#main #quiz .quizResults h3 {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  #main #quiz .quizArea h3,
  #main #quiz .quizResults h3 {
    font-size: 32px;
  }
}

#main #quiz .questionCount {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 20px;
}

#main #quiz .questionCount span {
  font-weight: 700;
}

#main #quiz .quizLevel span {
  display: block;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  line-height: 1.5em;
  margin-top: 30px;
  font-weight: 600;
}

#main #quiz ol,
#main #quiz ul {
  list-style-type: none;
}

#main #quiz ol.questions li {
  margin-left: 0;
}

#main #quiz ul {
  padding-inline-start: 0;
  padding-left: 0;
}

#main #quiz ul.answers {
  margin-left: 0;
  margin-bottom: 20px;
}

#main #quiz ul.responses li {
  margin: 0;
}

#main #quiz ul.responses li p {
  padding-right: 120px;
}

#main #quiz ul.responses li p span {
  display: block;
  font-weight: bold;
  font-size: 18px;
}

#main #quiz .quizResultsCopy {
  clear: both;
  margin-top: 20px;
}

#main #quiz .startQuiz,
#main #quiz .nextQuestion,
#main #quiz .backToQuestion,
#main #quiz .questions li.question,
#main #quiz .questions li.question .responses,
#main #quiz .questions li.question .responses .correct,
#main #quiz .questions li.question .responses .incorrect,
#main #quiz .quizResults {
  display: none;
}

#main #quiz .nextQuestion.checkAnswer {
  display: block;
}

#main #quiz .button.nextQuestion, #main #quiz .button.checkAnswer {
  background-color: #FFBF2F;
  color: #26393b;
  padding: 15px 30px;
  position: absolute;
  right: 30px;
  bottom: 30px;
}

#main #quiz .quizName span:first-child {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

#main #quiz .wrapper {
  background: rgba(255, 255, 255, 0.65);
  padding-top: 120px;
  padding-bottom: 120px;
  background-size: 100%;
}

#main #quiz .wrapper::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 576px) {
  #main #quiz .wrapper {
    padding-top: 60px;
  }
}

#main #quiz .wrapper .left {
  width: calc(41.66667% - 28.33333px);
  float: left;
  margin-left: 20px;
  text-align: right;
}

@media screen and (max-width: 576px) {
  #main #quiz .wrapper .left {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    text-align: center;
  }
}

#main #quiz .wrapper .left p {
  color: #B02FB2;
  font-weight: 700;
}

#main #quiz .wrapper .middle {
  width: calc(25% - 25px);
  float: left;
  margin-left: 20px;
  padding-left: 80px;
}

@media screen and (max-width: 576px) {
  #main #quiz .wrapper .middle {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    padding: 0;
    height: 250px;
  }
}

#main #quiz .wrapper .middle .go-button {
  height: 250px;
  width: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translateY(-50%);
}

@media screen and (max-width: 576px) {
  #main #quiz .wrapper .middle .go-button {
    position: relative;
    display: block;
    margin: 50% auto;
    top: 0;
    left: 0;
  }
}

#main #quiz .wrapper .middle .dashed-line {
  position: absolute;
  left: 20%;
  top: -10%;
  z-index: 99;
  transform: translateY(-50%);
}

@media screen and (max-width: 1200px) {
  #main #quiz .wrapper .middle .dashed-line {
    display: none;
  }
}

#main #quiz .wrapper .middle .dashed-line-straight {
  display: none;
}

@media screen and (max-width: 576px) {
  #main #quiz .wrapper .middle .dashed-line-straight {
    display: block;
  }
}

#main #quiz .wrapper .right {
  width: calc(33.33333% - 26.66667px);
  float: left;
  margin-left: 20px;
}

@media screen and (max-width: 1200px) {
  #main #quiz .wrapper .right {
    display: none;
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

#main #quiz .wrapper .right .woman {
  position: absolute;
  bottom: 0;
  right: 0;
}

#main #flavor-trap {
  background-image: url(../../img/cottoncandy.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  position: relative;
  overflow: hidden;
}

#main #flavor-trap .rotate {
  position: absolute;
  transform: translate(-50%, -50%);
}

#main #flavor-trap .rotate.left {
  bottom: 0;
  left: 0;
}

#main #flavor-trap .rotate.right {
  top: 20%;
  right: 0;
}

#main #flavor-trap .wrapper {
  background-image: url(../../img/prp.svg);
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: top left;
}

#main #flavor-trap .wrapper .candy-facts::after {
  clear: both;
  content: "";
  display: block;
}

#main #flavor-trap .wrapper .candy-facts .monster {
  width: calc(75% - 35px);
  float: left;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  #main #flavor-trap .wrapper .candy-facts .monster {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

#main #flavor-trap .wrapper .candy-facts .monster .candy-monster {
  position: relative;
  z-index: 9999;
  margin-top: -150px;
  margin-left: -120px;
}

@media screen and (max-width: 768px) {
  #main #flavor-trap .wrapper .candy-facts .monster .candy-monster {
    display: block;
    margin-top: -50px;
    margin-left: 0;
  }
}

#main #flavor-trap .wrapper .candy-facts .fact {
  width: calc(25% - 25px);
  float: left;
  margin-left: 20px;
  float: right;
  text-align: right;
  margin-bottom: 60px;
  z-index: 999;
  position: relative;
}

@media screen and (max-width: 768px) {
  #main #flavor-trap .wrapper .candy-facts .fact {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

#main #flavor-trap .wrapper .candy-facts .fact h3 {
  color: #5BCCDE;
}

#main #flavor-trap .wrapper .candy-facts .fact h4 {
  color: #FFBF2F;
}

#main #flavor-trap .wrapper .candy-facts .fact h4 span {
  font-size: 52px;
  display: block;
}

#main #poison {
  background-image: linear-gradient(to bottom, #51ecc7, #217893);
  /*carousel*/
}

#main #poison::after {
  clear: both;
  content: "";
  display: block;
}

#main #poison .wrapper {
  background: none;
}

#main #poison .description {
  width: calc(75% - 35px);
  float: left;
  margin-left: 20px;
  margin-left: 0;
}

@media screen and (max-width: 768px) {
  #main #poison .description {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

#main #poison .description .text {
  width: calc(58.33333% - 31.66667px);
  float: left;
  margin-left: 20px;
  display: inline-block;
  vertical-align: middle;
  float: none;
  margin-left: 0;
}

@media screen and (max-width: 1200px) {
  #main #poison .description .text {
    width: calc(75% - 35px);
    float: left;
    margin-left: 20px;
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  #main #poison .description .text {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

#main #poison .description .text h2 {
  font-size: 38px;
}

@media screen and (max-width: 576px) {
  #main #poison .description .text h2 {
    font-size: 24px;
  }
}

#main #poison .description .text.small {
  margin-left: 0;
  float: none;
  width: 40%;
}

@media screen and (max-width: 1200px) {
  #main #poison .description .text.small {
    width: 100%;
  }
}

#main #poison .swiper-container {
  width: 100%;
  height: 100%;
  margin: 20px 0 30px 0;
}

#main #poison .gallery-top {
  width: calc(25% - 25px);
  float: left;
  margin-left: 20px;
  margin-left: 0;
  margin-top: 0;
  float: right;
}

@media screen and (max-width: 768px) {
  #main #poison .gallery-top {
    float: left;
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

#main #poison .gallery-top .swiper-slide {
  opacity: 0;
  background-color: #afe2b2;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  #main #poison .gallery-top .swiper-slide {
    max-width: 50%;
    margin-left: 25%;
  }
}

@media screen and (max-width: 576px) {
  #main #poison .gallery-top .swiper-slide {
    max-width: 100%;
    margin-left: auto;
  }
}

#main #poison .gallery-top .swiper-slide.swiper-slide-active {
  opacity: 1;
}

#main #poison .gallery-top .swiper-slide img {
  display: block;
  margin: 10px auto 20px auto;
  max-height: 150px;
}

#main #poison .gallery-top .swiper-slide p {
  color: #26393b;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  margin: 0;
  padding: 15px 5px;
}

#main #poison .gallery-top .swiper-slide p.ele-name {
  background-color: #d5eded;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

#main #poison .gallery-thumbs {
  height: auto;
  width: 70%;
  box-sizing: border-box;
  padding: 0;
}

@media screen and (max-width: 768px) {
  #main #poison .gallery-thumbs {
    width: 100%;
  }
}

#main #poison .gallery-thumbs .swiper-slide {
  width: 18% !important;
  height: 100%;
  margin-left: 10px;
  background-color: #d5eded;
  border-radius: 10px;
  padding-top: 5px;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  #main #poison .gallery-thumbs .swiper-slide {
    display: block;
    margin-left: 10px;
    width: 18% !important;
  }
}

@media screen and (max-width: 576px) {
  #main #poison .gallery-thumbs .swiper-slide {
    width: 16% !important;
  }
}

#main #poison .gallery-thumbs .swiper-slide p {
  font-weight: 600;
  color: #26393b;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0;
}

#main #poison .gallery-thumbs .swiper-slide p.ele-id {
  text-align: left;
  padding-bottom: 15px;
}

@media screen and (max-width: 768px) {
  #main #poison .gallery-thumbs .swiper-slide p.ele-id {
    padding-bottom: 5px;
  }
}

#main #poison .gallery-thumbs .swiper-slide p.element {
  font-size: 60px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  #main #poison .gallery-thumbs .swiper-slide p.element {
    font-size: 26px;
  }
}

#main #poison .gallery-thumbs .swiper-slide p.ele-num {
  text-align: center;
}

@media screen and (max-width: 768px) {
  #main #poison .gallery-thumbs .swiper-slide p.ele-num {
    font-size: 10px;
  }
}

#main #poison .gallery-thumbs .swiper-slide p.ele-name {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

@media screen and (max-width: 768px) {
  #main #poison .gallery-thumbs .swiper-slide p.ele-name {
    font-size: 9px;
  }
}

#main #poison .gallery-thumbs .swiper-slide p.ele-name.green {
  background-color: #77DD88;
}

#main #poison .gallery-thumbs .swiper-slide p.ele-name.blue {
  background-color: #99DDCC;
}

#main #poison .gallery-thumbs .swiper-slide p.ele-name.pink {
  background-color: #DDBBBB;
}

#main #poison .gallery-thumbs .swiper-slide-thumb-active {
  display: none;
}

#main #vape {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom left;
  background: linear-gradient(90deg, #ddcd1b 0%, #e745a7 100%);
}

#main #vape .wrapper {
  padding-top: 60px;
  padding-bottom: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}

#main #vape .wrapper .top-half::after {
  clear: both;
  content: "";
  display: block;
}

#main #vape .wrapper .top-half .left {
  width: calc(41.66667% - 28.33333px);
  float: left;
  margin-left: 20px;
  position: relative;
  z-index: 10;
}

@media screen and (max-width: 1200px) {
  #main #vape .wrapper .top-half .left {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

#main #vape .wrapper .top-half .right {
  width: calc(58.33333% - 31.66667px);
  float: left;
  margin-left: 20px;
}

@media screen and (max-width: 1200px) {
  #main #vape .wrapper .top-half .right {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

#main #vape .wrapper .top-half .right img {
  height: 340px;
  margin-top: -65px;
  width: calc(58.33333% - 31.66667px);
  float: left;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  #main #vape .wrapper .top-half .right img {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    margin-top: -15px;
  }
}

#main #vape .wrapper .top-half .right p {
  display: inline-block;
  width: calc(41.66667% - 28.33333px);
  float: left;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  #main #vape .wrapper .top-half .right p {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

#main #vape .wrapper .top-half .right p span {
  color: #FFBF2F;
}

@media screen and (max-width: 576px) {
  #main #vape .wrapper .top-half .right p span {
    color: #971BEC;
  }
}

#main #vape .bottom-half {
  margin-top: 140px;
}

#main #vape .bottom-half::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 576px) {
  #main #vape .bottom-half {
    margin-top: 40px;
  }
}

#main #vape .bottom-half .left {
  width: calc(58.33333% - 31.66667px);
  float: left;
  margin-left: 20px;
}

@media screen and (max-width: 576px) {
  #main #vape .bottom-half .left {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

#main #vape .bottom-half .left h3, #main #vape .bottom-half .left h4, #main #vape .bottom-half .left img {
  display: inline-block;
  vertical-align: bottom;
}

#main #vape .bottom-half .left .float {
  display: inline-block;
}

#main #vape .bottom-half .left h4 {
  color: #B02FB2;
}

#main #vape .bottom-half .left h4 span {
  font-size: 32px;
}

#main #vape .bottom-half .left h3 {
  color: #FF00FF;
}

#main #vape .bottom-half .right {
  width: calc(41.66667% - 28.33333px);
  float: left;
  margin-left: 20px;
  background-image: url(../../img/vapor.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
}

@media screen and (max-width: 576px) {
  #main #vape .bottom-half .right {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    display: none;
  }
}

#main #vape .bottom-half .right p {
  text-align: right;
  width: 75%;
  float: right;
}

#main #facts {
  max-height: 600px;
  overflow: hidden;
  /*round slider*/
}

@media screen and (max-width: 1200px) {
  #main #facts {
    max-height: 500px;
  }
}

@media screen and (max-width: 768px) {
  #main #facts {
    max-height: 475px;
  }
}

@media screen and (max-width: 576px) {
  #main #facts {
    max-height: 375px;
  }
}

#main #facts .wrapper {
  background-image: linear-gradient(to bottom, #2369ae, #53a0fd 48%, #51dcec);
  padding-top: 30px;
}

#main #facts .wheel {
  width: 80%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  #main #facts .wheel {
    margin: 80px auto 0 auto;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #main #facts .wheel {
    margin: 80px -60px 0 auto;
  }
}

@media screen and (max-width: 576px) {
  #main #facts .wheel {
    margin: 80px 35px 0 auto;
    width: 80%;
  }
}

#main #facts .wheel > * {
  flex-grow: 1;
}

#main #facts .circle--rotate {
  border-radius: 50%;
  border: 10px #fff dashed;
  width: 100%;
  height: 100%;
  margin: 0 auto 0;
  position: relative;
  transform: rotate(0deg);
  z-index: 0;
  padding: 0;
}

@media screen and (max-width: 1200px) {
  #main #facts .circle--rotate {
    width: 80%;
    height: 80%;
  }
}

@media screen and (max-width: 768px) {
  #main #facts .circle--rotate {
    width: 500px;
    height: 500px;
  }
}

@media screen and (max-width: 576px) {
  #main #facts .circle--rotate {
    width: 400px;
    height: 400px;
  }
}

#main #facts .circle--rotate > li {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
  z-index: 10;
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  #main #facts .circle--rotate > li {
    width: 500px;
    height: 500px;
  }
}

@media screen and (max-width: 576px) {
  #main #facts .circle--rotate > li {
    width: 400px;
    height: 400px;
  }
}

#main #facts .circle--rotate > li div {
  visibility: visible;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  font-size: 20px;
  color: #26393b;
  border-radius: 50%;
  background-color: #fff;
  height: 300px;
  width: 300px;
  margin: -10% auto;
  transition: all 500ms ease;
}

@media screen and (max-width: 1200px) {
  #main #facts .circle--rotate > li div {
    height: 200px;
    width: 200px;
  }
}

@media screen and (max-width: 768px) {
  #main #facts .circle--rotate > li div {
    height: 150px;
    width: 150px;
  }
}

@media screen and (max-width: 576px) {
  #main #facts .circle--rotate > li div {
    height: 100px;
    width: 100px;
  }
}

#main #facts .circle--rotate > li div:before {
  border-radius: 100%;
  content: '';
  background-image: linear-gradient(87deg, #e9ce00, #eb11dc);
  padding: 8px;
  width: 300px;
  height: 300px;
  top: -8px;
  left: -8px;
  position: absolute;
  z-index: -1;
}

@media screen and (max-width: 1200px) {
  #main #facts .circle--rotate > li div:before {
    height: 200px;
    width: 200px;
  }
}

@media screen and (max-width: 768px) {
  #main #facts .circle--rotate > li div:before {
    height: 150px;
    width: 150px;
  }
}

@media screen and (max-width: 576px) {
  #main #facts .circle--rotate > li div:before {
    height: 100px;
    width: 100px;
  }
}

#main #facts .circle--rotate > li div img {
  padding: 25px;
}

#main #facts .circle--rotate > li div p {
  display: block;
  position: absolute;
  right: 0;
  font-size: 16px;
}

@media screen and (max-width: 1200px) {
  #main #facts .circle--rotate > li div p {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    width: 350px;
    margin: 20% -35% 0 0;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  #main #facts .circle--rotate > li div p {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    width: 250px;
    margin: 20% -35% 0 0;
    font-size: 14px;
  }
}

@media screen and (max-width: 576px) {
  #main #facts .circle--rotate > li div p {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    width: 250px;
    margin: 20% -75% 0 0;
    font-size: 14px;
  }
}

#main #facts .circle--slider {
  position: relative;
}

@media screen and (max-width: 768px) {
  #main #facts .circle--slider {
    margin-left: -20%;
  }
}

#main #facts .circle--slider .rotate--circle {
  margin: 0 auto;
}

#main #facts .circle--slider .circle--rotate > li div {
  position: relative;
  overflow: visible;
  background-color: #fff;
}

#main #facts .circle--slider .circle--rotate > li div p {
  opacity: 0;
  transition: 1s ease;
}

#main #facts .circle--slider .circle--rotate > li.active div {
  font-size: 20px;
  background-color: #fff;
  transition: all 1s;
}

#main #facts .circle--slider .circle--rotate > li.active div p {
  opacity: 1;
}

#main #facts .circle--slider .count2 li:nth-child(2) {
  transform: rotate(180deg);
}

#main #facts .circle--slider .count3 li:nth-child(2) {
  transform: rotate(120deg);
}

#main #facts .circle--slider .count3 li:nth-child(3) {
  transform: rotate(240deg);
}

#main #facts .circle--slider .count4 li:nth-child(2) {
  transform: rotate(90deg);
}

#main #facts .circle--slider .count4 li:nth-child(3) {
  transform: rotate(180deg);
}

#main #facts .circle--slider .count4 li:nth-child(4) {
  transform: rotate(270deg);
}

#main #facts .circle--slider .count5 li:nth-child(2) {
  transform: rotate(72deg);
}

#main #facts .circle--slider .count5 li:nth-child(3) {
  transform: rotate(144deg);
}

#main #facts .circle--slider .count5 li:nth-child(4) {
  transform: rotate(216deg);
}

#main #facts .circle--slider .count5 li:nth-child(5) {
  transform: rotate(-72deg);
}

#main #facts .circle--slider .prev,
#main #facts .circle--slider .next {
  position: absolute;
  cursor: pointer;
  font-size: 88px;
  line-height: .8;
  height: auto;
  width: auto;
  padding: 0 10px 15px 10px;
  box-shadow: 20px 20px 30px 8px rgba(0, 0, 0, 0.5);
  top: 20%;
  transform: translateY(-50%);
  margin: auto;
  display: block;
}

#main #facts .circle--slider .prev span,
#main #facts .circle--slider .next span {
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-weight: 700;
}

#main #facts .circle--slider .prev {
  background-color: #575BDE;
  color: #FFBF2F;
  left: -180px;
}

@media screen and (max-width: 1200px) {
  #main #facts .circle--slider .prev {
    left: 3%;
  }
}

@media screen and (max-width: 768px) {
  #main #facts .circle--slider .prev {
    left: 10%;
  }
}

@media screen and (max-width: 576px) {
  #main #facts .circle--slider .prev {
    left: 20px;
  }
}

#main #facts .circle--slider .next {
  background-color: #FFBF2F;
  color: #575BDE;
  right: -180px;
}

@media screen and (max-width: 1200px) {
  #main #facts .circle--slider .next {
    right: 3%;
  }
}

@media screen and (max-width: 768px) {
  #main #facts .circle--slider .next {
    right: 10%;
  }
}

@media screen and (max-width: 576px) {
  #main #facts .circle--slider .next {
    right: 20px;
  }
}

.st-custom-button {
  cursor: pointer;
}

/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fa/fontawesome-webfont.eot?v=4.6.3");
  src: url("../fonts/fa/fontawesome-webfont.eot?#iefix&v=4.6.3") format("embedded-opentype"), url("../fonts/fa/fontawesome-webfont.woff2?v=4.6.3") format("woff2"), url("../fonts/fa/fontawesome-webfont.woff?v=4.6.3") format("woff"), url("../fonts/fa/fontawesome-webfont.ttf?v=4.6.3") format("truetype"), url("../fonts/fa/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.28571em;
  text-align: center;
}

.fa-ul {
  padding-left: 0;
  margin-left: 2.14286em;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  position: absolute;
  left: -2.14286em;
  width: 2.14286em;
  top: 0.14286em;
  text-align: center;
}

.fa-li.fa-lg {
  left: -1.85714em;
}

.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eee;
  border-radius: .1em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right {
  margin-left: .3em;
}

/* Deprecated as of 4.4.0 */
.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: .3em;
}

.fa.pull-right {
  margin-left: .3em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.fa-stack-1x, .fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-envelope-o:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-o:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-gear:before,
.fa-cog:before {
  content: "";
}

.fa-trash-o:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-file-o:before {
  content: "";
}

.fa-clock-o:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-arrow-circle-o-down:before {
  content: "";
}

.fa-arrow-circle-o-up:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-play-circle-o:before {
  content: "";
}

.fa-rotate-right:before,
.fa-repeat:before {
  content: "";
}

.fa-refresh:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-dedent:before,
.fa-outdent:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-video-camera:before {
  content: "";
}

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: "";
}

.fa-pencil:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-edit:before,
.fa-pencil-square-o:before {
  content: "";
}

.fa-share-square-o:before {
  content: "";
}

.fa-check-square-o:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-times-circle-o:before {
  content: "";
}

.fa-check-circle-o:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-mail-forward:before,
.fa-share:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-arrows-h:before {
  content: "";
}

.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-gears:before,
.fa-cogs:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-thumbs-o-up:before {
  content: "";
}

.fa-thumbs-o-down:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-heart-o:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-linkedin-square:before {
  content: "";
}

.fa-thumb-tack:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-lemon-o:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-bookmark-o:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-facebook-f:before,
.fa-facebook:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-feed:before,
.fa-rss:before {
  content: "";
}

.fa-hdd-o:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-hand-o-right:before {
  content: "";
}

.fa-hand-o-left:before {
  content: "";
}

.fa-hand-o-up:before {
  content: "";
}

.fa-hand-o-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-group:before,
.fa-users:before {
  content: "";
}

.fa-chain:before,
.fa-link:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-cut:before,
.fa-scissors:before {
  content: "";
}

.fa-copy:before,
.fa-files-o:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-save:before,
.fa-floppy-o:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-money:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-unsorted:before,
.fa-sort:before {
  content: "";
}

.fa-sort-down:before,
.fa-sort-desc:before {
  content: "";
}

.fa-sort-up:before,
.fa-sort-asc:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-rotate-left:before,
.fa-undo:before {
  content: "";
}

.fa-legal:before,
.fa-gavel:before {
  content: "";
}

.fa-dashboard:before,
.fa-tachometer:before {
  content: "";
}

.fa-comment-o:before {
  content: "";
}

.fa-comments-o:before {
  content: "";
}

.fa-flash:before,
.fa-bolt:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-paste:before,
.fa-clipboard:before {
  content: "";
}

.fa-lightbulb-o:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-cloud-download:before {
  content: "";
}

.fa-cloud-upload:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-bell-o:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-cutlery:before {
  content: "";
}

.fa-file-text-o:before {
  content: "";
}

.fa-building-o:before {
  content: "";
}

.fa-hospital-o:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-mobile-phone:before,
.fa-mobile:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-mail-reply:before,
.fa-reply:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-folder-o:before {
  content: "";
}

.fa-folder-open-o:before {
  content: "";
}

.fa-smile-o:before {
  content: "";
}

.fa-frown-o:before {
  content: "";
}

.fa-meh-o:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-keyboard-o:before {
  content: "";
}

.fa-flag-o:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "";
}

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-unlink:before,
.fa-chain-broken:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-calendar-o:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-minus-square-o:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-pencil-square:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "";
}

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "";
}

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "";
}

.fa-euro:before,
.fa-eur:before {
  content: "";
}

.fa-gbp:before {
  content: "";
}

.fa-dollar:before,
.fa-usd:before {
  content: "";
}

.fa-rupee:before,
.fa-inr:before {
  content: "";
}

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "";
}

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "";
}

.fa-won:before,
.fa-krw:before {
  content: "";
}

.fa-bitcoin:before,
.fa-btc:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-text:before {
  content: "";
}

.fa-sort-alpha-asc:before {
  content: "";
}

.fa-sort-alpha-desc:before {
  content: "";
}

.fa-sort-amount-asc:before {
  content: "";
}

.fa-sort-amount-desc:before {
  content: "";
}

.fa-sort-numeric-asc:before {
  content: "";
}

.fa-sort-numeric-desc:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-youtube-play:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitbucket-square:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-gittip:before,
.fa-gratipay:before {
  content: "";
}

.fa-sun-o:before {
  content: "";
}

.fa-moon-o:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-arrow-circle-o-right:before {
  content: "";
}

.fa-arrow-circle-o-left:before {
  content: "";
}

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "";
}

.fa-dot-circle-o:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-turkish-lira:before,
.fa-try:before {
  content: "";
}

.fa-plus-square-o:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: "";
}

.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-spoon:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-automobile:before,
.fa-car:before {
  content: "";
}

.fa-cab:before,
.fa-taxi:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-file-pdf-o:before {
  content: "";
}

.fa-file-word-o:before {
  content: "";
}

.fa-file-excel-o:before {
  content: "";
}

.fa-file-powerpoint-o:before {
  content: "";
}

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: "";
}

.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: "";
}

.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: "";
}

.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "";
}

.fa-file-code-o:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: "";
}

.fa-circle-o-notch:before {
  content: "";
}

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: "";
}

.fa-ge:before,
.fa-empire:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-wechat:before,
.fa-weixin:before {
  content: "";
}

.fa-send:before,
.fa-paper-plane:before {
  content: "";
}

.fa-send-o:before,
.fa-paper-plane-o:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-circle-thin:before {
  content: "";
}

.fa-header:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-sliders:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-newspaper-o:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bell-slash-o:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-eyedropper:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-area-chart:before {
  content: "";
}

.fa-pie-chart:before {
  content: "";
}

.fa-line-chart:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-cc:before {
  content: "";
}

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: "";
}

.fa-meanpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-intersex:before,
.fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-facebook-official:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-hotel:before,
.fa-bed:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-yc:before,
.fa-y-combinator:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-battery-4:before,
.fa-battery-full:before {
  content: "";
}

.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "";
}

.fa-battery-2:before,
.fa-battery-half:before {
  content: "";
}

.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "";
}

.fa-battery-0:before,
.fa-battery-empty:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-sticky-note-o:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-hourglass-o:before {
  content: "";
}

.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "";
}

.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "";
}

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: "";
}

.fa-hand-scissors-o:before {
  content: "";
}

.fa-hand-lizard-o:before {
  content: "";
}

.fa-hand-spock-o:before {
  content: "";
}

.fa-hand-pointer-o:before {
  content: "";
}

.fa-hand-peace-o:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-tv:before,
.fa-television:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-calendar-plus-o:before {
  content: "";
}

.fa-calendar-minus-o:before {
  content: "";
}

.fa-calendar-times-o:before {
  content: "";
}

.fa-calendar-check-o:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-map-o:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-commenting:before {
  content: "";
}

.fa-commenting-o:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-credit-card-alt:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-pause-circle-o:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stop-circle-o:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-wheelchair-alt:before {
  content: "";
}

.fa-question-circle-o:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-volume-control-phone:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-signing:before,
.fa-sign-language:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: "";
}

.fa-fa:before,
.fa-font-awesome:before {
  content: "";
}
