.share-modal {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20000;
    bottom: 0;
    
    .share-modal-overlay {
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.5);
        display: block;  
    }
    .share-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        min-height: 50px;
        padding: 40px;
        height: auto;
        @include tablet-portrait-lt {
            transform: none;
            left: 0;
            right: 0;
        }
    }
    .share-button-container {
        padding: 40px;
    }
    .rrssb-buttons {
        height: 50px;
        a {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 10px;
            padding: 0;
            
            i {
                font-size: 25px;
                height: 25px;
                width: 25px;
                color: white;
                margin:0;
            }
        }
    }
    .modal-close-button {
        position: absolute;
        top: -50px;
        right: 0;
        font-size: 40px;
        z-index: 20001;
        cursor:pointer;
    }
}