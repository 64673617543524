// ==================================================
// Grid Math
// ==================================================
// Grid Values you can go either fluid (%) or fixed (px/em/ex/rem etc.)
$width: 100%;
$max-width: $max-grid-width;

@mixin row() {
  float: left;
  width: $width;
  clear: both;
}

// ==================================================
// Base Grid
// ==================================================
%container,
.container {
  @include grid-container();
  margin: 0 auto;
  max-width: $max-width;
}

%row,
.row {
  @include row;
}

// Nested Column Classes via mixin or class; you choose.
@mixin first() {
  margin-left: 0;
}

@mixin last() {
  margin-right: 0;
}

.firstcol {
  margin-left: 0;
}

.lastcol {
  margin-right: 0;
}

// Base Grid
%col1,
.col1 {
  @include grid-column(1);
}

%col2,
.col2 {
  @include grid-column(2);
}

%col3,
.col3 {
  @include grid-column(3);
}

%col4,
.col4 {
  @include grid-column(4);
}

%col5,
.col5 {
  @include grid-column(5);
}

%col6,
.col6 {
  @include grid-column(6);
}

%col7,
.col7 {
  @include grid-column(7);
}

%col8,
.col8 {
  @include grid-column(8);
}

%col9,
.col9 {
  @include grid-column(9);
}

%col10,
.col10 {
  @include grid-column(10);
}

%col11,
.col11 {
  @include grid-column(11);
}

%col12,
.col12 {
  @include grid-column(12);
}

%col1,
%col10,
%col11,
%col12,
%col2,
%col3,
%col4,
%col5,
%col6,
%col7,
%col8,
%col9,
.col1,
.col10,
.col11,
.col12,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8,
.col9 {
  @include tablet-portrait-lt {
    display: block;
    float: none;
    margin-left: 0;
    margin-right: 0;
    padding: 20px;
    position: relative;
    width: 100%;
  }
}

// ==================================================
// Grid Clearing
// ==================================================
%clearfix,
.clearfix,
.row {
  zoom: 1;

  &::after {
    clear: both;
  }

  &::after,
  &::before {
    content: '\0020';
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    width: 0;
  }
}
