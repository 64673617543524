header{
  height:100px;
  padding:1em 2em;
  position:relative;
  background-image: linear-gradient(95deg, #00d7af, #575bde);
  .social{
    padding-top:15px;
    padding-left:30px;
    position:relative;
    z-index:999;
    margin-left:100px;
    
    a{
      display:inline-block;
    }
  }
}